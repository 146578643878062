import axios from "axios";
import { Dispatch } from "redux";
import { ActionTypes } from "../types";
import { StoreState } from "../../redux";
import { LogoutAction, LoadDataAction } from "../actions/auth";
import { getOrgId } from "../selectors/authSelectors";

export interface EnumPsyQuizItem {
  id: number;
  from_quiz: number;
  type: string;
  text: string;
  key: string;
  rank: number;
  user_answer: string;
}

// export interface EnumTeamMemberItem {}

// ('PsyQuiz:Grit', 'PsyQuiz:Grit'), # 10 questions (very much)
// ('PsyQuiz:EQ', 'PsyQuiz:EQ'), # 25 questions (always)
// ('PsyQuiz:Visionary', 'PsyQuiz:Visionary'), # 21 questions (very much)
// ('PsyQuiz:Influencer', 'PsyQuiz:Influencer'), # 16 questions (very much)
// ('PsyQuiz:Confidece', 'PsyQuiz:Confidece'), # 14 questions (very much)
// ('PsyQuiz:Team', 'PsyQuiz:Team'), # 56 questions (strongly agree)
// ('PsyQuiz:Safety', 'PsyQuiz:Safety'), #

export interface teamData {
  quizzes: {
    grit: Array<EnumPsyQuizItem>;
    eq: Array<EnumPsyQuizItem>;
    visionary: Array<EnumPsyQuizItem>;
    influencer: Array<EnumPsyQuizItem>;
    confidence: Array<EnumPsyQuizItem>;
    team: Array<EnumPsyQuizItem>;
    safety: Array<EnumPsyQuizItem>;
  };
  team: [];
}

export interface quizSetExpectation {
  data: [];
}

export interface quizGetAction {
  type: ActionTypes.getQuiz;
  payload: quizSetExpectation;
}

export interface loadQuizAction {
  type: ActionTypes.loadQuiz;
  payload: any;
}

export interface LoadQuizDataAction {
  type: ActionTypes.loadQuizUpdateData;
  payload: any;
}

export const loadQuizUpdateData = (index: string, data: any) => {
  // use Dispatch as type annotation
  return async (dispatch: Dispatch) => {
    dispatch<loadQuizAction>({
      type: ActionTypes.loadQuiz,
      payload: {
        index: index,
        data: { data: data }
      }
    });
  };
};

export interface quizPostExpectation {
  data: string;
}

export interface SubmitQuizDataAction {
  type: ActionTypes.submitQuiz;
  payload: quizPostExpectation;
}

export const submitQuiz = (index: string, data: any) => {
  // use Dispatch as type annotation
  return async (dispatch: Dispatch, getState: () => StoreState) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      };

      const orgId = getOrgId(getState());
      const url = `${process.env.REACT_APP_SERVER_API_URL}api`;
      const quizURL = `${url}/teamquiz/${orgId}/full`;
      axios.defaults.withCredentials = true;

      const quizPostObject = {
        quiz: data
      };

      //   const response = await axios.post<mtmData>(mtmURL, config);
      const response = await axios.post<quizPostExpectation>(
        quizURL,
        quizPostObject,
        config
      );
      // TODO: save new comment in redux
      dispatch<SubmitQuizDataAction>({
        type: ActionTypes.submitQuiz,
        payload: response.data
      });
      dispatch<LoadDataAction>({
        type: ActionTypes.loadData,
        payload: { detail: "Loading Data" }
      });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        if (error.response.status == 401) {
          // If get error from server that token is not ok, send back to login
          localStorage.removeItem("token");
          dispatch<LogoutAction>({
            type: ActionTypes.logoutUser,
            payload: error.response.data
          });
        }
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      console.log(error);
    }
  };
};

export const getQuiz = (index: string, quizType: string) => {
  // use Dispatch as type annotation
  return async (dispatch: Dispatch, getState: () => StoreState) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      };
      const orgId = getOrgId(getState());

      const url = `${process.env.REACT_APP_SERVER_API_URL}api`;
      const quizURL = `${url}/teamquiz/${orgId}/${quizType}/`;

      axios.defaults.withCredentials = true;

      const response = await axios.get<teamData>(quizURL, config);
      dispatch<loadQuizAction>({
        type: ActionTypes.loadQuiz,
        payload: {
          index: index,
          data: response.data
        }
      });

      //   dispatch<LoadDataAction>({
      //     type: ActionTypes.loadData,
      //     payload: { detail: "Loading Data" }
      //   });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        if (error.response.status == 401) {
          // If get error from server that token is not ok, send back to login
          localStorage.removeItem("token");
          dispatch<LogoutAction>({
            type: ActionTypes.logoutUser,
            payload: error.response.data
          });
        }
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      console.log(error);
    }
  };
};
