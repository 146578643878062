import "./Team.scss";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { StoreState } from "../../../redux";
import Wrapper from "../../components/Wrapper/Wrapper";
import { pathURL } from "../../../redux/actions/routes";
import Card from "react-bootstrap/Card";
import { LinkContainer } from "react-router-bootstrap";
import {
  getQuizGrit,
  getQuizEQ,
  getQuizVisionary,
  getQuizInfluencer,
  getQuizConfidence,
  getQuizTeam
} from "../../../redux/selectors/teamSelectors";

// Using match with typescript is weird, used this logic to set up the type:
// https://stackoverflow.com/questions/48138111/what-typescript-type-should-i-use-to-reference-the-match-object-in-my-props
interface MatchParams {
  name: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {
  path: string;
  url: string;
}

interface AppProps {
  pathURL: (path: string, url: string) => void;
  match: MatchProps;
  grit_quiz: any;
  eq_quiz: any;
  visionary_quiz: any;
  influencer_quiz: any;
  confidence_quiz: any;
  team_quiz: any;
}

class _Team extends React.Component<AppProps> {
  constructor(props: AppProps) {
    super(props);
  }

  componentDidMount() {
    this.props.pathURL(this.props.match.path, this.props.match.url);
  }

  removeRow = (row: string): void => {
    console.log("Row to remove: ", row);
    // TODO: Connect this function to remove the row from the data object
  };

  render() {
    const teamTableData = [
      {
        id: "table_item_1",
        first_name: "Blanche",
        last_name: "Devereaux",
        title: "CEO"
      },
      {
        id: "table_item_2",
        first_name: "Dorothy",
        last_name: "Zbornak",
        title: "CFO"
      },
      {
        id: "table_item_3",
        first_name: "Rose",
        last_name: "Nylund",
        title: "CPO"
      },
      {
        id: "table_item_4",
        first_name: "Sophia",
        last_name: "Petrillo",
        title: "COO"
      }
    ];

    return (
      <div className="App team-wrap">
        <Wrapper>
          <div>Team</div>
          <Card key="quiz-grit">
            <LinkContainer to="/grit-scale">
              <Card.Header>Grit Assessment</Card.Header>
            </LinkContainer>
            <Card.Body>
              Here are a number of statements that may or may not apply to you.
              There are no right or wrong answers, so just answer honestly,
              considering how you compare to most people. At the end, you’ll get
              a score that reflects how passionate and persevering you see
              yourself to be.
              {this.props.grit_quiz.length > 0 && (
                <span>Number of Questions: {this.props.grit_quiz.length}</span>
              )}
            </Card.Body>
          </Card>
          <Card key="quiz-eq">
            <LinkContainer to="/eq-scale">
              <Card.Header>EQ Assessment</Card.Header>
            </LinkContainer>
            <Card.Body>
              Great leaders move us—they inspire, motivate, and energize us.
              How? They do it through emotional intelligence. Dan Goleman woke
              us all up when he published his groundbreaking book on the topic
              (in 1995). Since then we’ve learned a lot about EI competencies,
              such as self-awareness and empathy, and about what people can do
              to develop them.
              <br></br>
              <br></br>
              Here’s how you can gain a deeper understanding of your own
              emotional intelligence: First, respond to the statements below as
              honestly as possible, and we’ll show you how you rated yourself on
              five critical dimensions of EI. Reflecting on your strengths and
              where you can improve is important, but don’t stop there. Other
              people’s perspectives matter too. After reviewing your scores, ask
              one or two trusted friends to evaluate you using the same
              statements, to learn whether your own insights match what others
              see in you. Courtesy of the Harvard Business Review.
              {this.props.eq_quiz.length > 0 && (
                <span>Number of Questions: {this.props.eq_quiz.length}</span>
              )}
            </Card.Body>
          </Card>
          <Card key="quiz-visionary">
            <LinkContainer to="/visionary-scale">
              <Card.Header>Visionary-Pragmatist Assessment</Card.Header>
            </LinkContainer>
            <Card.Body>
              For each statement, click the button in the column that best
              describes you. Please answer questions as you see them (rather
              than how you think you should see them), and don&apos;t worry if
              some questions seem to score in the &quot;wrong direction.&quot;
              {this.props.visionary_quiz.length > 0 && (
                <span>
                  Number of Questions: {this.props.visionary_quiz.length}
                </span>
              )}
            </Card.Body>
          </Card>
          <Card key="quiz-influencer">
            <LinkContainer to="/influencer-scale">
              <Card.Header>Influencer Assessment</Card.Header>
            </LinkContainer>
            <Card.Body>
              For each statement, click the button in the column that best
              describes you. Please answer questions as you see them (rather
              than how you think you should see them), and don&apos;t worry if
              some questions seem to score in the &quot;wrong direction.&quot;
              {this.props.influencer_quiz.length > 0 && (
                <span>
                  Number of Questions: {this.props.influencer_quiz.length}
                </span>
              )}
            </Card.Body>
          </Card>
          <Card key="quiz-confidence">
            <LinkContainer to="/confidence-scale">
              <Card.Header>Self-Confidence Assessment</Card.Header>
            </LinkContainer>
            <Card.Body>
              For each statement, click the button in the column that best
              describes you. Please answer questions as you see them (rather
              than how you think you should see them), and don&apos;t worry if
              some questions seem to score in the &quot;wrong direction.&quot;
              {this.props.confidence_quiz.length > 0 && (
                <span>
                  Number of Questions: {this.props.confidence_quiz.length}
                </span>
              )}
            </Card.Body>
          </Card>
          <Card key="quiz-team">
            <LinkContainer to="/team-scale">
              <Card.Header>Team Assessment</Card.Header>
            </LinkContainer>
            <Card.Body>
              For each statement, click the button in the column that best
              describes you. Please answer questions as you see them (rather
              than how you think you should see them), and don&apos;t worry if
              some questions seem to score in the &quot;wrong direction.&quot;
              {this.props.team_quiz.length > 0 && (
                <span>Number of Questions: {this.props.team_quiz.length}</span>
              )}
            </Card.Body>
          </Card>
          {/* <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Title (will be a dropdown)</th>
                <th>Button</th>
              </tr>
            </thead>
            <tbody>
              {teamTableData.map((data) => (
                <tr key={data.id}>
                  <td></td>
                  <td>{data.first_name}</td>
                  <td>{data.last_name}</td>
                  <td>
                    <DropdownButton
                      id="dropdown-basic-button"
                      title="Dropdown button"
                      variant="outline-primary"
                    >
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Something else
                      </Dropdown.Item>
                    </DropdownButton>
                  </td>
                  <td>
                    <Button
                      variant="outline-primary"
                      onClick={() => this.removeRow(data.id)}
                    >
                      Remove Teammate
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Button
            variant="outline-primary"
            onClick={() => console.log("Add function to add row")}
          >
            Add new team mate
          </Button> */}
        </Wrapper>
      </div>
    );
  }
}

const mapStateToProps = (
  state: StoreState
): {
  grit_quiz: any;
  eq_quiz: any;
  visionary_quiz: any;
  influencer_quiz: any;
  confidence_quiz: any;
  team_quiz: any;
} => {
  return {
    grit_quiz: getQuizGrit(state),
    eq_quiz: getQuizEQ(state),
    visionary_quiz: getQuizVisionary(state),
    influencer_quiz: getQuizInfluencer(state),
    confidence_quiz: getQuizConfidence(state),
    team_quiz: getQuizTeam(state)
  };
};

export const Team = connect(mapStateToProps, {
  pathURL
})(_Team);
