import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { pathURL } from "../../../redux/actions/routes";
import { onboardUser } from "../../../redux/actions/auth";
import {
  getAuthStatus,
  getOnboarding
} from "../../../redux/selectors/authSelectors";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import BrandNav from "../../../public/Nav";

// Multi-step flow from this guide: https://css-tricks.com/the-magic-of-react-based-multi-step-forms/

class Onboarding extends Component {
  constructor() {
    super();
    this.state = {
      error: false,
      stage: "",
      engagement: "",
      cofounders: null,
      cofounders_div: null,
      skillsets: ""
    };
  }

  submit = async (e) => {
    e.preventDefault();

    this.props.onboardUser({
      stage: this.state.stage,
      engagement: this.state.engagement,
      cofounders: this.state.cofounders,
      cofounders_div: this.state.cofounders_div,
      skillsets: this.state.skillsets
    });

    this.setState({
      redirect: true
    });
  };
  componentDidMount() {
    const { match } = this.props;
    this.props.pathURL(match.path, match.url);
  }

  handleStageRadioChange = (event) => {
    const { id } = event.target;

    this.setState({
      stage: id
    });
  };

  handleSkillsetRadioChange = (event) => {
    const { id } = event.target;

    this.setState({
      skillsets: id
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  };

  render() {
    if (this.state.redirect || this.props.onboarded) {
      return <Redirect to={"/"} />;
    }

    return (
      <>
        <BrandNav />
        <Card body className="de-public" border="info">
          <h2>ONBOARDING</h2>
          <small>Welcome to DealEngine!</small>
          <Form onSubmit={this.submit}>
            <Form.Label as="legend">What stage is your company in?</Form.Label>
            <Form.Group
              required
              as={Row}
              controlId="mtm_development_stage"
              onChange={this.handleStageRadioChange}
            >
              <Col sm={6}>
                <Form.Check
                  required
                  type="radio"
                  label="Idea"
                  name="stageRadios"
                  id="Idea"
                  //   defaultChecked
                />
                <Form.Check
                  required
                  type="radio"
                  label="Prototype"
                  name="stageRadios"
                  id="Prototype"
                />
                <Form.Check
                  required
                  type="radio"
                  label="MVP"
                  name="stageRadios"
                  id="MVP"
                />
              </Col>
              <Col sm={6}>
                <Form.Check
                  required
                  type="radio"
                  label="Market Launch"
                  name="stageRadios"
                  id="Market Launch"
                />
                <Form.Check
                  required
                  type="radio"
                  label="Post-revenue"
                  name="stageRadios"
                  id="Post-revenue"
                />
                <Form.Check
                  required
                  type="radio"
                  label="Scaling"
                  name="stageRadios"
                  id="Scaling"
                />
              </Col>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label htmlFor="engagement">
                What is your level of engagement with the customer right now?
              </Form.Label>
              <Form.Control
                as="select"
                id="engagement"
                name="engagement"
                defaultValue={this.state.engagement}
                onChange={this.handleChange}
                required
              >
                <option value={""} key="options" hidden>
                  {"Select One"}
                </option>
                {[
                  "I need to validate market demand",
                  "Customers are awaiting my product",
                  "I have some sales",
                  "Customers love me!",
                  "I am ready to tap bigger markets"
                ].map((data) => (
                  <option value={data} key={data}>
                    {data}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="inputPassword">
              <Form.Label>
                How many cofounders are on the team right now?
              </Form.Label>
              <Form.Control
                type="number"
                name="cofounders"
                key="cofounders"
                defaultValue={this.state.cofounders}
                onChange={this.handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="inputPassword">
              <Form.Label>
                How many founders from{" "}
                <a
                  href="https://www.google.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Underrepresented groups{" "}
                </a>{" "}
                do you have
              </Form.Label>
              <Form.Control
                type="number"
                name="cofounders_div"
                key="cofounders_div"
                defaultValue={this.state.cofounders_div}
                onChange={this.handleChange}
                required
              />
            </Form.Group>
            <Form.Label>
              Does your team have all the needed skill sets?
            </Form.Label>
            <Form.Group
              as={Row}
              controlId="ob_skillsets"
              onChange={this.handleSkillsetRadioChange}
            >
              <Col sm={6}>
                <Form.Check
                  required
                  type="radio"
                  label="Yes"
                  name="skillsetRadios"
                  id="Yes"
                />
                <Form.Check
                  required
                  type="radio"
                  label="No"
                  name="skillsetRadios"
                  id="No"
                />
                <Form.Check
                  required
                  type="radio"
                  label="I don't know"
                  name="skillsetRadios"
                  id="I don't know"
                />
              </Col>
            </Form.Group>
            <Button variant="outline-primary" type="submit">
              GET STARTED
            </Button>
          </Form>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  success: getAuthStatus(state),
  onboarded: getOnboarding(state)
});

Onboarding.propTypes = {};

export default connect(mapStateToProps, { pathURL, onboardUser })(Onboarding);
