import React, { Component } from "react";
import "./Public.scss";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { pathURL } from "../redux/actions/routes";
import { loginUser } from "../redux/actions/auth";
import { getAuthStatus, getOnboarding } from "../redux/selectors/authSelectors";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import BrandNav from "./Nav";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      error: false
    };
  }
  email = "";
  password = "";
  state = {
    redirect: false
  };

  submit = async (e) => {
    e.preventDefault();
    this.props.loginUser({
      email: this.email,
      password: this.password,
      scope: "admin"
    });

    this.setState({
      redirect: true
    });
  };
  componentDidMount() {
    const { match } = this.props;
    this.props.pathURL(match.path, match.url);
  }

  render() {
    // TODO: turn this on once onboarding flow is completed
    if (this.props.success && this.props.onboarded) {
      return <Redirect to={"/"} />;
    }

    if (this.props.success && !this.props.onboarded) {
      return <Redirect to={"/onboarding"} />;
    }

    // if (this.props.success) {
    //   return <Redirect to={"/"} />;
    // }

    return (
      <>
        <BrandNav />
        <Card body className="de-public" border="info">
          <h2>SIGN IN</h2>
          <small>Welcome back to DealEngine!</small>
          <Form onSubmit={this.submit}>
            <Form.Group controlId="inputEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                required
                onChange={(e) => (this.email = e.target.value)}
              />
              {/* no placeholder in design, id was on Control id="inputEmail" */}
              <Form.Text className="text-muted">
                We will never share your email with anyone else.
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="inputPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                onChange={(e) => (this.password = e.target.value)}
                required
              />
            </Form.Group>
            <Button variant="outline-primary" type="submit">
              SIGN IN
            </Button>
            <Button variant="outline-secondary" as={Link} to="/register">
              CREATE AN ACCOUNT
            </Button>
          </Form>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  success: getAuthStatus(state),
  onboarded: getOnboarding(state)
});

Login.propTypes = {};

export default connect(mapStateToProps, { pathURL, loginUser })(Login);
