import { SetAppRoutesAction, SetPathURLAction } from "./actions";
import { SetErrorAction } from "./actions/errors";
import {
  LoadDataAction,
  LoginAction,
  LogoutAction,
  RegisterAction,
  OnboardingAction
} from "./actions/auth";
import { GlossaryAction } from "./actions/glossary";
import {
  PlaybookAction,
  LoadPlaybookDataAction,
  PostPlaybookDataAction
} from "./actions/playbook";
import { mtmGetAction, LoadMtmDataAction } from "./actions/mtm";
import {
  loadQuizAction,
  quizGetAction,
  LoadQuizDataAction,
  SubmitQuizDataAction
} from "./actions/team";

export enum ActionTypes {
  // Will default to 0. When adding a second item, will default to 1...etc
  appRoutes,
  pathURL,
  setError,
  loginUser,
  logoutUser,
  registerUser,
  getGlossary,
  getPlaybook,
  loadData,
  getMtm,
  getQuiz,
  loadQuiz,
  loadMtmUpdateData,
  loadQuizUpdateData,
  postMtm,
  loadPlaybookUpdateData,
  postPlaybook,
  onboardUser,
  submitQuiz
}

export type Action =
  | SetAppRoutesAction
  | SetPathURLAction
  | SetErrorAction
  | LoginAction
  | RegisterAction
  | LogoutAction
  | GlossaryAction
  | PlaybookAction
  | LoadDataAction
  | mtmGetAction
  | LoadMtmDataAction
  | LoadPlaybookDataAction
  | PostPlaybookDataAction
  | OnboardingAction
  | quizGetAction
  | loadQuizAction
  | LoadQuizDataAction
  | SubmitQuizDataAction;
