import React, { Component } from "react";
import "./Public.scss";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { pathURL } from "../redux/actions/routes";
import { resetPassword } from "../redux/actions/auth";

class PasswordSet extends Component {
  constructor() {
    super();
    this.state = {
      error: false
    };
  }
  password = "";
  email = "";
  password_confirm = "";
  token = "";
  state = {
    redirect: false
  };

  submit = async (e) => {
    e.preventDefault();

    const passwordPostObject = {
      password: this.password,
      password_confirm: this.password_confirm,
      email: this.email,
      token: this.token
    };

    this.props.resetPassword(passwordPostObject);

    this.setState({
      redirect: true
    });
  };
  componentDidMount() {
    const { match, location } = this.props;
    const idMatch = location.search.substring(
      location.search.lastIndexOf("id=") + 3,
      location.search.lastIndexOf("&email")
    );
    const emailMatch = location.search.substring(
      location.search.lastIndexOf("&email") + 7
    );

    this.token = idMatch;
    this.email = emailMatch;
    this.props.pathURL(match.path, match.url);
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/login"} />;
    }

    return (
      <div>
        <form className="form-signin" onSubmit={this.submit}>
          <h1 className="h3 mb-3 font-weight-normal">Please sign in</h1>
          <label htmlFor="inputPassword" className="sr-only">
            New Password
          </label>
          <input
            type="password"
            id="inputPassword"
            className="form-control"
            placeholder="Password"
            required
            onChange={(e) => (this.password = e.target.value)}
          />
          <label htmlFor="inputPasswordConfirm" className="sr-only">
            Password Confirm
          </label>
          <input
            type="password"
            id="inputPasswordConfirm"
            className="form-control"
            placeholder="Password Confirm"
            required
            onChange={(e) => (this.password_confirm = e.target.value)}
          />
          <button className="btn btn-lg btn-primary btn-block" type="submit">
            Change Password
          </button>
        </form>
        <div>
          <Link to="/login" className="btn btn-primary">
            Login
          </Link>
          <Link to="/register" className="btn btn-primary">
            Register
          </Link>
        </div>
      </div>
    );
  }
}

PasswordSet.propTypes = {};

export default connect(null, { pathURL, resetPassword })(PasswordSet);
