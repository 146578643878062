import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { loadQuizUpdateData, submitQuiz } from "../../../redux/actions/team";
import { connect } from "react-redux";
import { pathURL } from "../../../redux/actions/routes";
import { getAuthStatus } from "../../../redux/selectors/authSelectors";
import Form from "react-bootstrap/Form";
import { getQuizVisionary } from "../../../redux/selectors/teamSelectors";

// Multi-step flow from this guide: https://css-tricks.com/the-magic-of-react-based-multi-step-forms/

class visionaryQuiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      validated: false,
      currentStep: 1,
      totalQuestions: null,
      user_answer: "",
      answers: [{ user_answer: "" }]
    };
  }

  handleSubmit = async (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({
        validated: true
      });
    } else {
      e.preventDefault();
      this.props.submitQuiz("visionary", this.props.visionary_quiz);

      this.setState({
        redirect: true
      });
    }
  };
  componentDidMount() {
    const { match } = this.props;
    this.props.pathURL(match.path, match.url);
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  };

  _next = () => {
    let currentStep = this.state.currentStep;
    currentStep = currentStep >= 1 ? 2 : currentStep + 1;
    this.setState({
      currentStep: currentStep
    });
  };

  _prev = () => {
    let currentStep = this.state.currentStep;
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep
    });
  };

  /*
   * the functions for our button
   */
  previousButton() {
    let currentStep = this.state.currentStep;
    if (currentStep !== 1) {
      return (
        <button
          className="btn btn-secondary"
          type="button"
          onClick={this._prev}
        >
          Previous
        </button>
      );
    }
    return null;
  }

  nextButton() {
    let currentStep = this.state.currentStep;
    if (currentStep < 2) {
      return (
        <button
          className="btn btn-primary float-right"
          type="button"
          onClick={this._next}
        >
          Continue
        </button>
      );
    }
    return null;
  }

  handleValueUpdate = (idx, type) => (evt) => {
    const newValue = this.props.visionary_quiz.map((data, sidx) => {
      if (idx !== data.id) return data;
      return { ...data, user_answer: evt.target.value };
    });
    this.props.loadQuizUpdateData("visionary", newValue);
    this.setState({ answers: newValue });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/team"} />;
    }

    return (
      <React.Fragment>
        <h1 className="h3 mb-3 font-weight-normal">Visionary Quiz</h1>
        <h1 className="h3 mb-3 font-weight-normal">
          Welcome to Deal Engine!...
        </h1>
        {/* <p>Step {this.state.currentStep} </p> */}

        <Form
          className="form-signin"
          onSubmit={this.handleSubmit}
          noValidate
          validated={this.state.validated}
        >
          {/*
          render the form steps and pass required props in
        */}
          <Step
            currentStep={this.state.currentStep}
            handleValueUpdate={this.handleValueUpdate}
            email={this.state.email}
            data={this.props.visionary_quiz}
          />
          {/* {this.previousButton()}
          {this.nextButton()} */}
          {this.state.validated && <div>Please fill out all questions</div>}
          <button className="btn btn-success btn-block">Get Started</button>
        </Form>
      </React.Fragment>
    );
  }
}

const veryMuchOptions = [
  { id: "5", text: "Very much like me" },
  { id: "4", text: "Mostly like me" },
  { id: "3", text: "Somewhat like me" },
  { id: "2", text: "Not much like me" },
  { id: "1", text: "Not like me at all" }
];

function Step(props) {
  if (props.currentStep !== 1) {
    return null;
  }
  return (
    <React.Fragment>
      {props.data.map((question) => {
        return (
          <div key={question.key}>
            <div key={question.key}>{question.text}</div>
            <div>
              <Form.Group className="form-group">
                {/* <Form.label htmlFor={question.key}>
                  Choose an option:
                </Form.label> */}
                <Form.Control
                  as="select"
                  id={question.key}
                  name={question.key}
                  defaultValue={question.user_answer}
                  onChange={props.handleValueUpdate(question.id, question.type)}
                  // isInvalid={!question.user_answer}
                  // required
                >
                  <option value={""} key={question.key + "null"} hidden>
                    {"Select One"}
                  </option>
                  {veryMuchOptions.map((data) => (
                    <option value={data.id} key={data.id + data.text}>
                      {data.text}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  success: getAuthStatus(state),
  visionary_quiz: getQuizVisionary(state)
});

visionaryQuiz.propTypes = {};

export default connect(mapStateToProps, {
  pathURL,
  loadQuizUpdateData,
  submitQuiz
})(visionaryQuiz);
