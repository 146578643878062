import axios from "axios";
import { Dispatch } from "redux";
import { ActionTypes } from "../types";
import { LogoutAction, LoadDataAction } from "../actions/auth";

export interface EnumGlossaryItem {
  id: number;
  term: string;
  is_used: boolean;
  acronymn: string;
  definition: string;
  rank: number;
  key: string;
}

// for comment: make it so when a value is updated it makes comment null, and the comment section defaults to "check back tomorrow..." if null

export interface GlossaryData {
  data: Array<EnumGlossaryItem>;
}

export interface GlossaryExpectation {
  data: [];
}

export interface GlossaryAction {
  type: ActionTypes.getGlossary;
  payload: GlossaryData;
}

const url = `${process.env.REACT_APP_SERVER_API_URL}api`;
const glossaryURL = `${url}/glossary`;

export const getGlossary = () => {
  // use Dispatch as type annotation
  return async (dispatch: Dispatch) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      };

      axios.defaults.withCredentials = true;

      //   const response = await axios.get<Glossary>(glossaryURL, axiosConfig);
      const response = await axios.get<GlossaryData>(glossaryURL, config);
      console.log("Glossary Data: ", response.data);
      dispatch<GlossaryAction>({
        type: ActionTypes.getGlossary,
        payload: response.data
      });
      dispatch<LoadDataAction>({
        type: ActionTypes.loadData,
        payload: { detail: "Loading Data" }
      });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        if (error.response.status == 401) {
          // If get error from server that token is not ok, send back to login
          localStorage.removeItem("token");
          dispatch<LogoutAction>({
            type: ActionTypes.logoutUser,
            payload: error.response.data
          });
        }
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      console.log(error);
    }
  };
};
