export const generatePlaybook = (data) => {
  const determineValue = (value) => {
    if (data && data[value]) {
      return data[value];
    }
    return false;
  };
  return {
    data: [
      {
        refKey: "lc_evidence",
        name: "Evidence",
        should_show: true, // this will determine if we should grey out
        is_current: true, // this will determine if this is where the nav tree opens
        lc_areas: [
          // Evidence: Team
          {
            refKey: "evidence_team_lc",
            name: "Team",
            description:
              "It is never too soon to start thinking about the team you'll need.",
            fa_tasks: [
              {
                refKey: "identify_cofounders",
                name: "Identify the right co-founding team",
                action_items: [
                  {
                    refKey: "action_build_team",
                    name: "Build your team",
                    sub_action_items: [
                      {
                        refKey: "find_cofounders",
                        name: "Find Co-founders",
                        description:
                          "The right co-founding team is a critical foundation for any company. You need to assess the strengths and skill sets need to accomplish your goals, and work backwards to figure out what people might be the best fit.",
                        user_answer: determineValue("find_cofounders"),
                        resources: [
                          {
                            description:
                              "LinkedIn is a great place to find people in all walks of life and with various areas of expertise",
                            url: "https://www.linkedin.com/"
                          },
                          {
                            description:
                              "At CoFounder Lab, you can get access to over 400,000 potential cofounders, team members and advisors on the largest startup platform on the planet.",
                            url: "https://cofounderslab.com/"
                          },
                          {
                            description:
                              "Reddit has several entrepreneur and startup focused groups. The Entrepreneur community alone has almost a million members.",
                            url: "https://www.reddit.com/r/Entrepreneur/"
                          }
                        ]
                      },
                      {
                        refKey: "find_interns",
                        name: "Find Interns/Apprentices",
                        description:
                          "A startup experience is often a great learning experience for students looking to get their feet wet.",
                        user_answer: determineValue("find_interns"),
                        resources: [
                          {
                            description:
                              "Check out the internship program by book company Chegg.",
                            url: "https://www.internships.com/"
                          },
                          {
                            description:
                              "Barefoot Students allows you to hire college students and recent grads in 195 countries. Post jobs & internships. Search student profiles.",
                            url: "https://www.barefootstudent.com/"
                          },
                          {
                            description:
                              "Pangea offers college talent on-demand. Their portal Pangea.app gives you an immediate, direct access to students ready to undertake any project.",
                            url: "https://pangea.app/"
                          },
                          {
                            description:
                              "Help apprentices kickstart their digital careers, by offering experience for a rapidly changing job market by working with your business.",
                            url: "https://acadium.com/get-an-apprentice/"
                          }
                        ]
                      },
                      {
                        refKey: "find_contractors",
                        name: "Find Contractors",
                        description:
                          "If you need more cost-effective doers, check out some of the marketplaces for on-demand hires.",
                        user_answer: determineValue("find_contractors"),
                        resources: [
                          {
                            description:
                              "Amazon Mechanical Turk (MTurk) is a crowdsourcing marketplace that makes it easier for individuals and businesses to outsource their processes and jobs to a distributed workforce who can perform these tasks virtually.",
                            url: "https://www.mturk.com/"
                          },
                          {
                            description:
                              "Fiverr offers a whole world of freelance talent at your fingertips.",
                            url: "https://www.fiverr.com/"
                          },
                          {
                            description:
                              "Upwork is the the world’s work marketplace, where you can engage the largest network of trusted independent professionals to unlock the full potential of your business.",
                            url: "https://www.upwork.com/"
                          }
                        ]
                      },
                      {
                        refKey: "identify_gaps",
                        name: "Identify Gaps",
                        description:
                          "Knowing what you're missing is often as important as knowing what you have. Take stock of your team and their capabilities. Make note of what's missing. ",
                        user_answer: determineValue("identify_gaps"),
                        resources: [
                          {
                            description:
                              "CoBloom presents: The Ultimate Startup Hiring Guide: How to Build a Winning Team",
                            url:
                              "https://drive.google.com/file/d/1cgRJIEN4FzhVOHvvpRMrAluJ8e0q_6Lt/view?usp=sharing"
                          },
                          {
                            description:
                              "Use this handy gap analysis calculator to figure out what skills you are lacking.",
                            url:
                              "https://docs.google.com/spreadsheets/d/1Z63B3eqGWALGtbxUkBmNa0f4NS4z9KFTs5wh1BH9ENM/edit?usp=sharing"
                          },
                          {
                            description:
                              "Entrepreneur magazine offers 9 tips guaranteed to build a winning team.",
                            url: "https://www.entrepreneur.com/article/292425"
                          },
                          {
                            description:
                              "PDF guide to the steps in successful team building.",
                            url:
                              "https://drive.google.com/file/d/1vjv-huFFWTCk2-PizF7FulIK-RnyOqx_/view?usp=sharing"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    refKey: "action_team_assessments",
                    name: "Team Assessments",
                    sub_action_items: [
                      {
                        refKey: "grit_scale",
                        name: "Grit Scale",
                        description:
                          "Get a free team assessment from DealEngine to understand the mindset and chemistry dynamics of your candidates and team. Answer a few questions and we’ll crunch the numbers.",
                        user_answer: determineValue("grit_scale"),
                        resources: [
                          {
                            description:
                              "Grit is passion and perseverance for long-term goals. Many say grit is a key trait for startup founder success. Take our quick five minute quiz and see how your Grit Score stacks up.",
                            url: "/grit-scale"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    refKey: "action_self_assessments",
                    name: "Self Assessments",
                    sub_action_items: [
                      {
                        refKey: "eq_scale",
                        name: "Emotional Intelligence Scale",
                        description:
                          "Get a free team assessment from DealEngine to understand the mindset and chemistry dynamics of your candidates and team. Answer a few questions and we’ll crunch the numbers.",
                        user_answer: determineValue("eq_scale"),
                        resources: [
                          {
                            description:
                              "Emotional Intelligence, or EQ, is the ability to understand, use, and manage your own emotions in positive ways to relieve stress, communicate effectively, empathize with others, overcome challenges and defuse conflict. Many say Emotional Intelligence is a key trait for startup founder success. Take our quick five minute quiz and see how your EQ Score stacks up.",
                            url: "/eq-scale"
                          }
                        ]
                      },
                      {
                        refKey: "vp_scale",
                        name: "Visionary-Pragmatist Scale",
                        description:
                          "Get a free team assessment from DealEngine to understand the mindset and chemistry dynamics of your candidates and team. Answer a few questions and we’ll crunch the numbers.",
                        user_answer: determineValue("vp_scale"),
                        resources: [
                          {
                            description:
                              "A Visionary-Pragmatist is a remarkably future-oriented, very imaginative person who easily develops a conceptual model, but can sometimes be over-imaginative and end up with pure speculations. Some of the strong visionaries possess an unusual power of foresight. Many say visionary-pragmatism is a key trait for startup founder success. Take our quick five minute quiz and see how your Visionary-Pragmatist Score stacks up.",
                            url: "/visionary-scale"
                          }
                        ]
                      },
                      {
                        refKey: "inf_scale",
                        name: "Influencer Scale",
                        description:
                          "Get a free team assessment from DealEngine to understand the mindset and chemistry dynamics of your candidates and team. Answer a few questions and we’ll crunch the numbers.",
                        user_answer: determineValue("inf_scale"),
                        resources: [
                          {
                            description:
                              "The Influencer Scale measures the persuadability powers of a leader. Many say leadership and ability to influence are key trait for startup founder success. Take our quick five minute quiz and see how your Influencer Score stacks up.",
                            url: "/influencer-scale"
                          }
                        ]
                      },
                      {
                        refKey: "team_scale",
                        name: "Team Effectiveness Scale",
                        description:
                          "Get a free team assessment from DealEngine to understand the mindset and chemistry dynamics of your candidates and team. Answer a few questions and we’ll crunch the numbers.",
                        user_answer: determineValue("team_scale"),
                        resources: [
                          {
                            description:
                              "This questionnaire examines team effectiveness from the perspective of eight dimensions: purpose and goals, roles, team processes, team relationships, intergroup relations, problem solving, passion and commitment, skills and learning.",
                            url: "/team-scale"
                          }
                        ]
                      },
                      {
                        refKey: "conf_scale",
                        name: "Self-Confidence Scale",
                        description:
                          "Get a free team assessment from DealEngine to understand the mindset and chemistry dynamics of your candidates and team. Answer a few questions and we'll crunch the numbers.",
                        user_answer: determineValue("conf_scale"),
                        resources: [
                          {
                            description:
                              "Launching a new company requires self-confidence. Many say self-confidence is a key trait for startup founder success, but what confidence level is too little or too much? Take our quick five minute quiz and see how your Self-Confidence Score stacks up.",
                            url: "/confidence-scale"
                          }
                        ]
                      },
                      {
                        refKey: "ps_scale",
                        name: "Psychological Safety Scale",
                        description:
                          "Get a free team assessment from DealEngine to understand the mindset and chemistry dynamics of your candidates and team. Answer a few questions and we’ll crunch the numbers.",
                        user_answer: determineValue("ps_scale"),
                        resources: [
                          {
                            description:
                              "Psychological safety a critical component of high-performing teams. A multi-year study by Google, Project Aristotle, discovered that psychological safety was the most important factor in high-performing teams.",
                            url: "/team"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    refKey: "action_assign_roles",
                    name: "Assign roles",
                    sub_action_items: [
                      {
                        refKey: "assign_resp",
                        name: "Assign responsibilities to co-founders",
                        description:
                          "If you have one or more founders, it’s imperative that you decide who will do what up front. Put it in writing.  Co-founder disagreements can destroy your business.",
                        user_answer: determineValue("assign_resp"),
                        resources: [
                          {
                            description:
                              "Make sure you are using a good set of project management tools. We've listed a few that are the best here, starting with a free account from Trello.",
                            url: "https://trello.com/signup"
                          },
                          {
                            description:
                              "Asana is another great tool that starts at the low price of free for a Basic account.",
                            url: "https://asana.com/"
                          },
                          {
                            description:
                              "Atlassian's Jira is free for up to a team of 10.",
                            url:
                              "https://www.atlassian.com/software/jira/pricing"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    refKey: "action_meet_mentors",
                    name: "Meet mentors",
                    sub_action_items: [
                      {
                        refKey: "find_mentors",
                        name: "Find relevant mentors",
                        description:
                          "Your company will greatly benefit from a mentor who has succeeded in your industry, and  can provide you with critical advice and guidance. Moreover, your Board of Advisors will lend a lot of credibility to any external-facing fundraising efforts.",
                        user_answer: determineValue("find_mentors"),
                        resources: [
                          {
                            description:
                              "LinkedIn is a great place to find people in all walks of life and with various areas of expertise.",
                            url: "https://www.linkedin.com/"
                          },
                          {
                            description:
                              "LunchClub offers smart introductions to relevant people, with curated 1:1 professional connections.",
                            url: "https://lunchclub.com/"
                          },
                          {
                            description:
                              "The Founder Institute offers a free legal template called the FAST Agreement that you can use to attract and legally retain advisors.",
                            url: "https://fi.co/fast"
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "ensure_diversity",
                name: "Ensure diverse representation among the team",
                action_items: [
                  {
                    refKey: "action_build_team",
                    name: "Build your team",
                    sub_action_items: [
                      {
                        refKey: "diversify_team",
                        name:
                          "Diversifying your team increases your success rate",
                        description:
                          "Numerous research now indicates that having a multifaceted team (skills, identity, demographics, etc) enhances the resilience and ultimate success of said team.",
                        user_answer: determineValue("diversify_team"),
                        resources: [
                          {
                            description:
                              "You can begin by doing an honest assessment of how diverse your team actually is, and to begin to develop an action plan to achieve a diversity goal. Begin by doing a simple diversity assessment.",
                            url:
                              "https://docs.google.com/spreadsheets/d/1vAkjq_8VeR8oaKXgrroOGuWseSgRE96NNy2EBf-2hfk/edit#gid=1179895272"
                          },
                          {
                            description:
                              "Diversify Tech is a talent directory and an excellent resource to help change the ratio in tech by hiring more underrepresented people.",
                            url: "https://www.diversifytech.co/hire/"
                          },
                          {
                            description:
                              "JumpStart.Me helps you build diverse teams with confidence, allowing you to gain diversity insights at every stage of the recruiting funnel and effortlessly source, engage, and hire a more inclusive workforce.",
                            url: "https://jumpstart.me/"
                          },
                          {
                            description:
                              "HackerEarth's Go-Getter’s guide to diversity hiring in tech.",
                            url:
                              "https://www.hackerearth.com/blog/talent-assessment/diversity-hiring-in-tech"
                          },
                          {
                            description:
                              "ProSky shares the top 9 benefits of creating A diverse organization",
                            url:
                              "https://talkingtalent.prosky.co/articles/title-9-benefits-of-creating-a-diverse-organization"
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "list_supporters",
                name: "Develop a list of potential partners/supporters",
                action_items: [
                  {
                    refKey: "action_find_partners",
                    name: "Find Partners",
                    sub_action_items: [
                      {
                        refKey: "find_partners",
                        name: "Find Partners",
                        description:
                          "Not everyone likes networking and the tasks associated with it, like reaching out to strangers and cold calling. However, the success of you company is contingent on making connections with people you don't know yet.",
                        user_answer: determineValue("find_partners"),
                        resources: [
                          {
                            description:
                              "LinkedIn is a great place to find people in all walks of life and with various areas of expertise.",
                            url: "https://www.linkedin.com/"
                          },
                          {
                            description:
                              "HubSpot offers the The Ultimate Guide to Business Development and How It Can Help Your Company Grow.",
                            url:
                              "https://blog.hubspot.com/sales/business-development"
                          },
                          {
                            description:
                              "LunchClub offers smart introductions to relevant people, with curated 1:1 professional connections.",
                            url: "https://lunchclub.com/"
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "prepare_journey",
                name: "Prepare for the journey ahead of you",
                action_items: [
                  {
                    refKey: "action_learn",
                    name: "Learn about the startup journey",
                    sub_action_items: [
                      {
                        refKey: "understand_journey",
                        name: "Understand the Startup Journey",
                        description:
                          "Make sure to understand what you are getting yourself into, by leveraging these free resourse from which you can learn as well as assess that startup journey for yourself.",
                        user_answer: determineValue("understand_journey"),
                        resources: [
                          {
                            description:
                              "How to Start a Startup by Steve Altman",
                            url: "http://startupclass.samaltman.com/"
                          },
                          {
                            description:
                              "GoodReads' Hello, Startup Reading List",
                            url:
                              "https://www.goodreads.com/list/show/88189.Hello_Startup_Reading_List"
                          },
                          {
                            description:
                              "Entrepreneur Magazine on the 8 Ways to Prepare for the Many Startup Obstacles Ahead",
                            url: "https://www.entrepreneur.com/article/248886"
                          }
                        ]
                      },
                      {
                        refKey: "get_buyin",
                        name: "Get buy-in and feedback from family and friends",
                        description:
                          "Spend time to make sure close family and friends 'buy into' your startup.  You’ll get great honest feedback and you may be establishing your pipeline for a friends and family raise.",
                        user_answer: determineValue("get_buyin"),
                        resources: [
                          {
                            description:
                              "LivePlan has tips on how to pitch your business to your family, friends and community.",
                            url:
                              "https://www.liveplan.com/blog/heres-how-and-why-to-pitch-your-business-to-friends-family-and-your-community/"
                          },
                          {
                            description:
                              "Entrepreneur Magazine on 5 Steps to Get Your Parents to Respect Your Entrepreneurial Dreams",
                            url: "https://www.entrepreneur.com/article/296619"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          // Evidence: Product
          {
            refKey: "evidence_product_lc",
            name: "Product",
            description: "Description for Evidence: Product",
            fa_tasks: [
              {
                refKey: "define_solution",
                name: "Define your solution",
                action_items: [
                  {
                    refKey: "action_define_solution",
                    name: "Define your solution",
                    sub_action_items: [
                      {
                        refKey: "define_product",
                        name: "Define product features",
                        description:
                          "There are lots of great online resources that can help you begin your product definition and test it.",
                        user_answer: determineValue("define_product"),
                        resources: [
                          {
                            description:
                              "Use the Value Proposition template to better align your product features to the customer's key needs and pains.",
                            url:
                              "https://www.smartsheet.com/value-proposition-positioning-templates"
                          },
                          {
                            description:
                              "Wide collection of customer interviewing templates to go out in the field and validate your product/market fit.",
                            url:
                              "https://drive.google.com/drive/folders/0B0-WhGDCaJbDMEFDRHFyWlRuTGc?usp=sharing"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    refKey: "action_write_description",
                    name: "Write product descriptions",
                    sub_action_items: [
                      {
                        refKey: "write_descriptions",
                        name: "Write product descriptions",
                        description:
                          "Once you have a good handle on the customers pains and needs, you can start to define and test the unique characteristics of your offering. Note that this exercise is not based on a fully-built product; you can leverage a written description, mockup, wireframe or other sort of crude prototype to define your solution.",
                        user_answer: determineValue("write_descriptions"),
                        resources: []
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "define_differentiation",
                name: "Define your differentiation",
                action_items: [
                  {
                    refKey: "action_define_differentiation",
                    name: "Define your differentiation",
                    sub_action_items: [
                      {
                        refKey: "define_differentiation",
                        name: "Write product descriptions",
                        description:
                          "A good product description will come in handy when you test your concept in real-life interviews with customers.",
                        user_answer: determineValue("define_differentiation"),
                        resources: [
                          {
                            description:
                              "Use the Value Proposition template to better align your product features to the customer's key needs and pains.",
                            url:
                              "https://www.smartsheet.com/value-proposition-positioning-templates"
                          },
                          {
                            description:
                              "Wide collection of customer interviewing templates to go out in the field and validate your product/market fit.",
                            url:
                              "https://drive.google.com/drive/folders/0B0-WhGDCaJbDMEFDRHFyWlRuTGc?usp=sharing"
                          },
                          {
                            description:
                              "Think about how your customer will get excited about your product. You can even use retail product descriptions for inspiration",
                            url:
                              "https://www.shopify.com/blog/8211159-9-simple-ways-to-write-product-descriptions-that-sell"
                          },
                          {
                            description:
                              '"How to Differentiate Your Startup from The Competition" – Guest Post by Martin Luenendonk.',
                            url:
                              "https://www.finsmes.com/2017/07/how-to-differentiate-your-startup-from-the-competition-guest-post-by-martin-luenendonk.html"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    refKey: "action_write_description",
                    name: "Write product descriptions",
                    sub_action_items: [
                      {
                        refKey: "write_descriptions",
                        name: "Write product descriptions",
                        description:
                          "Once you have a good handle on the customers pains and needs, you can start to define and test the unique characteristics of your offering. Note that this exercise is not based on a fully-built product; you can leverage a written description, mockup, wireframe or other sort of crude prototype to define your solution.",
                        user_answer: determineValue("write_descriptions"),
                        resources: []
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "check_competitors",
                name: "See if anyone else is doing what you are planning to do",
                action_items: [
                  {
                    refKey: "action_check_competitors",
                    name: "Preliminary competitor research",
                    sub_action_items: [
                      {
                        refKey: "research_competitors",
                        name:
                          "Understand who's doing similar things in your space",
                        description:
                          "Start your research, and dive into whose similar, or close, to your offering. How are they selling it, to whom.",
                        user_answer: determineValue("research_competitors"),
                        resources: [
                          {
                            description:
                              "SEMRush has the 5-Step Competitor Analysis Strategy That Never Fails!",
                            url:
                              "https://www.semrush.com/blog/5-step-competitor-analysis-strategy-never-fails/"
                          },
                          {
                            description:
                              "Use the Competitive Analysis tool from SimilarWeb.",
                            url:
                              "https://www.similarweb.com/corp/marketing/competitive-analysis/"
                          },
                          {
                            description:
                              "Find out from HubSpot what's a competitive analysis & how do you conduct one?",
                            url:
                              "https://blog.hubspot.com/marketing/competitive-analysis-kit"
                          },
                          {
                            description:
                              'Jamie Johnson has a nice piece on "Sizing Up the Competition: How to Conduct Competitive Research"',
                            url:
                              "https://www.uschamber.com/co/start/strategy/how-to-conduct-competitive-research"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    refKey: "action_write_description",
                    name: "Write product descriptions",
                    sub_action_items: [
                      {
                        refKey: "write_descriptions",
                        name: "Write product descriptions",
                        description:
                          "Once you have a good handle on the customers pains and needs, you can start to define and test the unique characteristics of your offering. Note that this exercise is not based on a fully-built product; you can leverage a written description, mockup, wireframe or other sort of crude prototype to define your solution.",
                        user_answer: determineValue("write_descriptions"),
                        resources: []
                      }
                    ]
                  }
                ]
              }
            ]
          },
          // Evidence: Market
          {
            refKey: "evidence_market_lc",
            name: "Market",
            description: "Description for Evidence: Market",
            fa_tasks: [
              {
                refKey: "identify_segments",
                name: "Begin to identify target segment(s)",
                action_items: [
                  {
                    refKey: "action_identify_segments",
                    name: "Identify most promising segment(s)",
                    sub_action_items: [
                      {
                        refKey: "identify_segments",
                        name: "Identify most promising segment(s)",
                        description:
                          "You will likely come across several possible segments that might like a good target segment. However, the reality is that you do not have the resources in terms of people, money and time to enter all markets. You will have to do some research and analysis to determine the best possible segment to pursue.",
                        user_answer: determineValue("identify_segments"),
                        resources: [
                          {
                            description:
                              "Defining your beachhead is key. The beachhead is your initial market entry point where you will have the most success and are poised to enter another market segment.",
                            url:
                              "https://corporatefinanceinstitute.com/resources/knowledge/strategy/beachhead-strategy/"
                          },
                          {
                            description:
                              "You can do a simple beachhead market entry analysis by comparing your top target segments side by side.",
                            url:
                              "https://docs.google.com/spreadsheets/d/19pV95wB19LM2bLJ8xljWM8BZ2wyhSjrIJCbd9lX3rtY/edit#gid=0"
                          },
                          {
                            description:
                              "The MaRS Startup Toolkit offers some great guidance in identifying target customer segments to focus your marketing resources",
                            url:
                              "https://learn.marsdd.com/article/identifying-target-customer-segments-to-focus-your-marketing-resources/"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    refKey: "action_define_problem",
                    name: "Define Problem",
                    sub_action_items: [
                      {
                        refKey: "define_problem",
                        name: "Define Problem",
                        description:
                          "Every new market offering needs to solve some sort of customer problem. The best way to determine that is to go out and do Customer Discovery, basically going out and interviewing people that might be customers some day.",
                        user_answer: determineValue("define_problem"),
                        resources: [
                          {
                            description:
                              "The Five Whys - Getting to the Root of a Problem",
                            url:
                              "https://www.mindtools.com/pages/article/newTMC_5W.htm"
                          },
                          {
                            description:
                              "IDEO's DesignKit's Methodology on 'How Might We'",
                            url: "https://www.designkit.org/methods/3"
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "interview_customers",
                name: "Interview prospective customers",
                action_items: [
                  {
                    refKey: "action_customer_discovery",
                    name: "Do Customer Discovery",
                    sub_action_items: [
                      {
                        refKey: "customer_discovery",
                        name: "Do Customer Discovery",
                        description:
                          "You will only validate your hypotheses by going out in the field and interviewing prospective customers. This is a lot easier than selling; you are only there to listen. Many prospective customers would be thrilled to vent about the problems related to their current solution.",
                        user_answer: determineValue("customer_discovery"),
                        resources: [
                          {
                            description:
                              "Define your perfect customer. You may have already created a User Persona. If not, you can start creating that persona.",
                            url: "https://slite.com/templates/persona"
                          },
                          {
                            description:
                              "Find prospective customers. If your company is B2B, then LinkedIn is an excellent place to start.",
                            url: "https://www.linkedin.com/feed/"
                          },
                          {
                            description:
                              "If your company is a B2C company, then you need to figure out where your target customers might congregate. Is it a group on Facebook or Nextdoor? Are there other forums where their common interests might bring them together?",
                            url: "https://www.facebook.com/"
                          },
                          {
                            description:
                              "When you are ready to interview them, you'll want to present your product concept, some mockups or possibly even a usable prototype for the users to evaluate. You can capture feedback in a Google Form or similar data collection tool.",
                            url: "https://www.google.com/forms/about/"
                          },
                          {
                            description:
                              "Make sure to be armed with your documented questions and hypotheses about what the customers actually want. You can again check out the Strategyzer templates to see sample trigger questions and a framework to test and validate your hypotheses you developed before interviewing.",
                            url:
                              "https://drive.google.com/drive/u/2/folders/1ObO4PaHny0iZwjD1gNpOQL9m9PvL_biG"
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "identify_pain",
                name: "Identify real customer pain points",
                action_items: [
                  {
                    refKey: "action_research_problem",
                    name: "Research big problems in the world",
                    sub_action_items: [
                      {
                        refKey: "research_problem",
                        name: "Research big problems in the world",
                        description:
                          "A great way to begin the brainstorm of business ideas is by researching what are the biggest problems in our nation and world. By virtue of the fact that they are widely discussed might mean that, in addition to saving the world, there is a very big market opportunity within it.",
                        user_answer: determineValue("research_problem"),
                        resources: [
                          {
                            description: "Y Combinator Requests for Startups",
                            url: "https://www.ycombinator.com/rfs/"
                          },
                          {
                            description: "What has Homebrew interested",
                            url: "https://quip.com/rcLXASq4IbIj"
                          },
                          {
                            description:
                              "Get to know 6000+ entrepreneurs and developers from more than 160 countries via sharing and discussing startup ideas.",
                            url: "https://www.ideaswatch.com/"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    refKey: "action_define_problem",
                    name: "Define Problem",
                    sub_action_items: [
                      {
                        refKey: "define_problem",
                        name: "Define Problem",
                        description:
                          "Every new market offering needs to solve some sort of customer problem. The best way to determine that is to go out and do Customer Discovery, basically going out and interviewing people that might be customers some day.",
                        user_answer: determineValue("define_problem"),
                        resources: [
                          {
                            description:
                              "The Five Whys - Getting to the Root of a Problem",
                            url:
                              "https://www.mindtools.com/pages/article/newTMC_5W.htm"
                          },
                          {
                            description:
                              "IDEO's DesignKit's Methodology on 'How Might We'",
                            url: "https://www.designkit.org/methods/3"
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "validate_solution",
                name: "Validate your solution",
                action_items: [
                  {
                    refKey: "action_understand_segments",
                    name: "Understand customer segments",
                    sub_action_items: [
                      {
                        refKey: "define_persona",
                        name: "Define User Persona",
                        description:
                          "Close your eyes and dream of your perfect customer. What are they like? What do they do? What are they motivated by? These are just some of the questions you answer when you create a complete User Persona, which describes one target customer in detail. Once you have that defined, you can go out and think about how to get 1,000 more just like that one.",
                        user_answer: determineValue("define_persona"),
                        resources: [
                          {
                            description:
                              "Slite offers free customizable user persona templates. A user persona, also known as a customer persona, is a comprehensive description of a company's ideal customer. The free plan offers up to 100 documents.",
                            url: "https://slite.com/templates/persona"
                          },
                          {
                            description:
                              "Miro's User Personas Template allows you to create target user profiles to better understand how to sell to them.",
                            url: "https://miro.com/templates/personas/"
                          },
                          {
                            description:
                              "Smaply has a persona generator to empathize with your customers and put them at the center. Smaply makes it easy for you to take your customers' perspective and better understand their expectations. You get one persona mapping with the free account.",
                            url: "https://www.smaply.com/tools/personas"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    refKey: "action_value_prop",
                    name: "Measure Value Proposition",
                    sub_action_items: [
                      {
                        refKey: "value_prop",
                        name: "Measure Value Proposition",
                        description:
                          "Value proposition is the measure of an innovation, service, or feature intended to make a company or product attractive to customers. How attractive is your offering to customers?",
                        user_answer: determineValue("value_prop"),
                        resources: [
                          {
                            description:
                              "The Value Proposition Canvas breaks the problem of identifying the value proposition down into discrete parts. This enables a person or team to address each part, step by step. On the right, the canvas profiles the customer by asking: what jobs are they trying to accomplish, what’s hard or unpleasant (pains) about their current way of doing it, and what's desirable or delightful (gains) in an ideal solution? The value proposition is mapped out by asking: what is the product or service, and how does it create the gains the customer wants and soothe the pains they don't?",
                            url:
                              "https://docs.google.com/document/d/1x8t-wFM5Fu2y8X6UAa_pqCRY23zXwQcAZTDvUX3VJaw/edit"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    refKey: "action_market_viability",
                    name: "Determine market viability",
                    sub_action_items: [
                      {
                        refKey: "market_via",
                        name: "Determine market viability",
                        description:
                          "You need to determine if your offering will be viable in the marketplace. Are you offering a product or a service that you can profitably deliver?  Run the numbers: is there enough customer demand so that you can cover costs and make a sufficient profit?",
                        user_answer: determineValue("market_via"),
                        resources: [
                          {
                            description:
                              "Pitching Sequoia? Here's the big question you'll need to answer",
                            url:
                              "https://pando.com/2013/02/20/sequoias-why-now/"
                          },
                          {
                            description:
                              "The Four Steps to the Epiphany: Successful Strategies for Products that Win",
                            url:
                              "https://www.amazon.com/Four-Steps-Epiphany-Successful-Strategies-dp-1119690358/dp/1119690358/ref=dp_ob_title_bk"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    refKey: "action_validate_solution",
                    name: "Validate your solution",
                    sub_action_items: [
                      {
                        refKey: "pivot",
                        name: "Pivot (if needed)",
                        description:
                          "Pivoting means you are changing your business model, based on the things you learned during the evidence stage. The Evidence stage is absolutely the very best time to pivot, assuming you have not yet found a viable model. Pivoting will become much more expensive the longer you wait.",
                        user_answer: determineValue("pivot"),
                        resources: [
                          {
                            description:
                              "The important thing is to listen to your prospective customers and ask yourself your key question - did my solution sufficiently meet their needs? If not, why is that? Are you targeting the wrong segment, not solving the right problem, or is the value proposition with an entirely different segment? Perhaps even B2B instead of B2C? The Customer Discovery framework allows you to pivot and start over. Trust us, it is a lot cheaper to pivot now than a year from now.",
                            url:
                              "https://drive.google.com/drive/u/2/folders/11AN-Rx3D52qePeLY13MIe9mni_CJ7bkq"
                          },
                          {
                            description:
                              'The Harvard Business Review offers "The Strategic Pivot: Rules for Entrepreneurs and Other Innovators"',
                            url:
                              "https://hbr.org/2011/02/how-and-when-to-pivot-rules-fo"
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "establish_presence",
                name: "Establish a market presence",
                action_items: [
                  {
                    refKey: "action_register_domain",
                    name: "Register a domain name",
                    sub_action_items: [
                      {
                        refKey: "register_domain",
                        name: "Register a domain name",
                        description:
                          "Get a matching domain to your business name.  An AOL email address or a website with free hosting and a name like mysite.wordpress.com makes it seem like either (a) you are not running a real business or (b) you don't plan to be around long.",
                        user_answer: determineValue("register_domain"),
                        resources: [
                          {
                            description:
                              "Google can help you find your place online. Choose from more than 300 domain endings. Get 24/7 Google support from real humans.",
                            url: "https://domains.google/"
                          },
                          {
                            description:
                              "GoDaddy is the world's largest services platform for entrepreneurs around the globe. They're on a mission to empower our worldwide community of 20+ million customers — and entrepreneurs everywhere — by giving them all the help and tools they need to grow online.",
                            url: "https://www.godaddy.com/"
                          },
                          {
                            description:
                              "Ionos offers a free domain with a web hosting package, including domain and email account, privacy protection and Wildcard SSL (https:).",
                            url:
                              "https://www.ionos.com/domains/free-domain-name"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    refKey: "action_social_media",
                    name: "Register social media profiles",
                    sub_action_items: [
                      {
                        refKey: "social_media",
                        name: "Register social media profiles",
                        description:
                          "Getting set up on the major social media channels (Facebook, LinkedIn, and Twitter, to start) will make marketing on them later easier. Also, it’s important to reserve your brand as a profile name. Try Knowem.com to reserve the names.",
                        user_answer: determineValue("social_media"),
                        resources: [
                          {
                            description:
                              "Easily manage all your social media and get results with Hootsuite. With the free starter account, you can manage three different social media accounts, including your choice among LinkedIn, Facebook, Twitter, Instagram and others.",
                            url: "https://www.hootsuite.com/"
                          },
                          {
                            description:
                              "Create a LinkedIn business page. Drive brand awareness & quality engagement with professional audiences by creating a LinkedIn Company Page. Explore how you can join conversations, grow & engage your audience. Learn more.",
                            url:
                              "https://business.linkedin.com/marketing-solutions/linkedin-pages"
                          },
                          {
                            description:
                              "Use the Twitter microblogging and social networking platform to reach your audience.",
                            url: "https://twitter.com/"
                          },
                          {
                            description:
                              "Facebook for Business gives you the latest news, advertising tips, best practices and case studies for using Facebook to meet your business goals.",
                            url: "https://www.facebook.com/business"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    refKey: "action_mailing",
                    name: "Have a mailing address",
                    sub_action_items: [
                      {
                        refKey: "mailing",
                        name: "Have a mailing address",
                        description:
                          "You will likely need a mailing address for your business and a PO Box will likely not cut it, as incorporation services typically prohibit that option. If you stay virtual, a good option might be a virtual office or colocation service.",
                        user_answer: determineValue("mailing"),
                        resources: [
                          {
                            description:
                              "Get an impressive and professional address for your business.",
                            url:
                              "https://www.intelligentoffice.com/virtual-address"
                          },
                          {
                            description:
                              "If you are ready for the real deal and get local office space, your best starting point might be something a bit more cost-effective from a co-working facility. WeWork is the global leader. You can check to see if they have something close to you.",
                            url: "https://www.wework.com/"
                          },
                          {
                            description:
                              "If you prefer to go more off-the-beaten-path with co-location, you can check out the League of Extraordinary Coworking Spaces to see if they offer something close to you.",
                            url: "https://lexc.co/"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    refKey: "action_business_cards",
                    name: "Get business cards",
                    sub_action_items: [
                      {
                        refKey: "business_cards",
                        name: "Get business cards",
                        description:
                          "As a startup founder, you’ll be doing a lot of networking, so order plenty of business cards. They are inexpensive enough that you can reorder them later if things change. If you are so beyond the business card, make sure that have a fast and easy way of sharing your contact information with your phone.",
                        user_answer: determineValue("business_cards"),
                        resources: [
                          {
                            description:
                              "VistaPrint allows you to create create business cards as unique as you.",
                            url: "https://www.vistaprint.com/business-cards"
                          },
                          {
                            description:
                              "Moo allows you to order a sample pack so you can touch and feel the premium range of papers and finishes for yourself. It’s free!",
                            url: "https://www.moo.com/"
                          },
                          {
                            description:
                              "If you are beyond paper cards, consider creating a QR code that sends users to either your contact page on your website or your LinkedIn profile. You can either have that image easily available on your phone or stick the QR code to your phone case.",
                            url: "https://www.linkedin.com/feed/"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    refKey: "action_website",
                    name: "Set up a website",
                    sub_action_items: [
                      {
                        refKey: "setup_website",
                        name: "Set up a website",
                        description:
                          "Get your website up and running as soon as possible. Today, it’s necessary for credibility.  Even if your product is not yet built, you can start with company information.",
                        user_answer: determineValue("setup_website"),
                        resources: [
                          {
                            description:
                              "Wix helps you create a website you’re proud of. Wix offers a platform that gives you the freedom to create, design, manage and develop your web presence exactly the way you want.",
                            url: "https://www.wix.com/"
                          },
                          {
                            description:
                              "Squarespace is the all-in-one solution for anyone looking to create a beautiful website. Domains, eCommerce, hosting, galleries, analytics, and 24/7 support.",
                            url: "https://www.squarespace.com/"
                          },
                          {
                            description:
                              "Google Cloud web hosting allows you to host static and dynamic websites in the cloud with Click to Deploy or customized solutions.",
                            url:
                              "https://cloud.google.com/solutions/web-hosting"
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "competitor_eval",
                name: "Detailed Competitor Evaluation",
                action_items: [
                  {
                    refKey: "action_competitor_eval",
                    name: "Detailed Competitor Evaluation",
                    sub_action_items: [
                      {
                        refKey: "detailed_comp_research",
                        name: "Detailed competitor research",
                        description:
                          "By now you know what other companies might be competing for your customers, but your research will have to go to the next level to understand how much of a challenge you might face during the sales process.",
                        user_answer: determineValue("detailed_comp_research"),
                        resources: [
                          {
                            description:
                              "Research competitors' patent and trademark filings",
                            url: "https://patents.google.com/"
                          },
                          {
                            description:
                              "Research any public filings if the company is public",
                            url:
                              "https://www.sec.gov/edgar/searchedgar/companysearch.html"
                          },
                          {
                            description:
                              "Subscribe to the Google News feed for all of your competitors",
                            url: "https://news.google.com/"
                          },
                          {
                            description:
                              "Interview their customers. It may be tricky to find them but if you can have a conversation with current customers, then you can quickly learn about their strengths and weaknesses, thus allowing you to craft better marketing materials to highlight your advantages",
                            url:
                              "https://drive.google.com/drive/u/2/folders/1ObO4PaHny0iZwjD1gNpOQL9m9PvL_biG"
                          },
                          {
                            description:
                              "This template from 280 Group allows you to compare features between your product and those of your competitors.",
                            url:
                              "https://docs.google.com/spreadsheets/d/132QiLbpwQXKasVQXIs7TDKAimS94Fs38BZdPzS6RkBk/edit#gid=1495147798"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          // Evidence: Money
          {
            refKey: "evidence_money_lc",
            name: "Money",
            description: "?",
            fa_tasks: [
              {
                refKey: "develop_model",
                name: "Develop a basic business and revenue model",
                action_items: [
                  {
                    refKey: "action_define_model",
                    name: "Define a business plan and/or Business Model Canvas",
                    sub_action_items: [
                      {
                        refKey: "define_model",
                        name:
                          "Define a business plan and/or Business Model Canvas",
                        description:
                          "Creating a business plan with financial projections forces you to think through details. Keep your plan a living breathing thing that you revisit and adapt regularly. Steve Blank's Business Model Canvas is a very precise way of documenting those key considerations",
                        user_answer: determineValue("define_model"),
                        resources: [
                          {
                            description:
                              "The business model canvas is a great tool to help you understand a business model in a straightforward, structured way. Using this canvas will lead to insights about the customers you serve, what value propositions are offered through what channels, and how your company makes money.",
                            url:
                              "https://canvanizer.com/new/business-model-canvas"
                          },
                          {
                            description:
                              "Free download of the Business Model Canvas in PDF form. Great for creating poster sized documents to be used with Post-It notes",
                            url:
                              "https://www.businessmodelsinc.com/about-bmi/tools/business-model-canvas/"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        refKey: "lc_acceptance",
        name: "Acceptance",
        should_show: true, // this will determine if we should grey out
        is_current: false, // this will determine if this is where the nav tree opens
        lc_areas: [
          // Acceptance: Team
          {
            refKey: "acceptance_company_lc",
            name: "Team",
            description: "?",
            fa_tasks: [
              {
                refKey: "choose_name",
                name: "Choose a business name",
                action_items: [
                  {
                    refKey: "action_choose_name",
                    name: "Choose a business name",
                    sub_action_items: [
                      {
                        refKey: "choose_name",
                        name: "Choose a business name",
                        description:
                          "You want a name that will stick in your target audience’s heads. And it shouldn’t already be taken by another company. Do Google searches and use a corporate name search tool to see if the name you have in mind is unique. Check at the state and Federal level.",
                        user_answer: determineValue("choose_name"),
                        resources: [
                          {
                            description:
                              "Make sure to do a trademark search, so you can protect your brand over time. You can do a free trademark search at the government website.",
                            url: "https://www.uspto.gov/"
                          },
                          {
                            description:
                              "Lots of resources from Google's Brand Resource Center.",
                            url: "https://about.google/brand-resource-center/"
                          },
                          {
                            description:
                              "Legal-Sherpa helps you protect your name, logo or slogan.",
                            url: "https://www.legal-sherpa.com/"
                          },
                          {
                            description:
                              "Entrepreneur Magazine features 5 must-use tools for brainstorming company names.",
                            url: "https://www.entrepreneur.com/article/238601"
                          },
                          {
                            description:
                              "Fuze Branding offers a name brainstorming worksheet.",
                            url:
                              "https://www.fuzebranding.com/brainstorm-worksheet-tips-for-naming-your-business/"
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "create_company",
                name: "Create your company",
                action_items: [
                  {
                    refKey: "action_create_company",
                    name: "Create your company",
                    sub_action_items: [
                      {
                        refKey: "incorporate",
                        name: "Incorporate / figure out legal structure",
                        description:
                          "Incorporating your startup can protect your personal assets. Talk over structure (corporation, LLC, sole proprietorship) with your attorney and accountant.",
                        user_answer: determineValue("incorporate"),
                        resources: [
                          {
                            description:
                              "8 Situations When You Need to Incorporate Your Startup",
                            url:
                              "https://mashable.com/2014/02/17/incorporate-your-startup/"
                          },
                          {
                            description:
                              "Stripe-Atlas helps you turn your idea into a startup. Stripe Atlas is a powerful, safe, and easy-to-use platform for forming a company. By removing lengthy paperwork, legal complexity, and numerous fees, Stripe Atlas helps you launch your startup from anywhere in the world.",
                            url: "https://stripe.com/atlas"
                          },
                          {
                            description:
                              "StartPack helps you launch your US LLC business from anywhere in the world. They handle the complex paperwork. You get a US Bank Account and access to US Payments.",
                            url: "https://www.startpack.io/"
                          },
                          {
                            description:
                              "Gust Launch makes incorporation easy and sets up founders for investment and growth. With powerful tools. Honest advice. And true compassion.",
                            url: "https://gust.com/launch"
                          }
                        ]
                      },
                      {
                        refKey: "apply_ein",
                        name: "Apply for an EIN",
                        description:
                          "An Employer Identification Number (EIN) helps you separate yourself from your business. You’ll need it if you plan to incorporate your business or open a business bank account.  Plus, with it you can avoid giving out your social security number (an opening  to identity theft). EIN numbers are free; apply online.",
                        user_answer: determineValue("apply_ein"),
                        resources: [
                          {
                            description:
                              "An Employer Identification Number (EIN) is also known as a Federal Tax Identification Number, and is used to identify a business entity. Generally, businesses need an EIN.",
                            url:
                              "https://www.irs.gov/businesses/small-businesses-self-employed/employer-id-numbers"
                          }
                        ]
                      },
                      {
                        refKey: "apply_license",
                        name: "Investigate and apply for business licenses",
                        description:
                          "You may need one, if not several, business licenses for your startup, depending on your industry and where you are located.  Most licenses are at the state or local level.  Here in the United States, the SBA has a helpful business license and permits tool.",
                        user_answer: determineValue("apply_license"),
                        resources: [
                          {
                            description:
                              "Resources to apply for licenses and permits. Most small businesses need a combination of licenses and permits from both federal and state agencies. The requirements — and fees — vary based on your business activities, location, and government rules.",
                            url:
                              "https://www.sba.gov/business-guide/launch-your-business/apply-licenses-permits"
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "advisory_resources",
                name: "Find free advisory resources",
                action_items: [
                  {
                    refKey: "action_advisory_resources",
                    name: "Find free advisory resources",
                    sub_action_items: [
                      {
                        refKey: "advisory_resources",
                        name: "Find free advisory resources",
                        description:
                          "Your local SBA office, SCORE, and other small business resources can provide you with free advice, access to business templates, and other tools.",
                        user_answer: determineValue("advisory_resources"),
                        resources: [
                          {
                            description:
                              "SCORE’s mission is to foster vibrant small business communities through mentoring and education. With the nation’s largest network of volunteer, expert business mentors, SCORE has helped more than 11 million entrepreneurs since 1964.",
                            url: "https://www.score.org/"
                          },
                          {
                            description:
                              "The Small Business Development Council",
                            url:
                              "https://www.sba.gov/about-sba/sba-locations/headquarters-offices/office-small-business-development-centers"
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "hire_employee",
                name: "Hire your first employee",
                action_items: [
                  {
                    refKey: "action_hire_employee",
                    name: "Hire your first employee",
                    sub_action_items: [
                      {
                        refKey: "hire_employee",
                        name: "Hire your first employee",
                        description:
                          "Depending on the type of business you have, you may need staff from day one (retail) or you may be able to outsource to  freelancers, interns, and third-party vendors for a while (service and tech businesses).   Just remember, trying to do everything yourself  takes you away from growing the business.",
                        user_answer: determineValue("hire_employee"),
                        resources: [
                          {
                            description: "Hire underrepresented folks in tech.",
                            url: "https://www.diversifytech.co/hire"
                          },
                          {
                            description:
                              "Finding the best fit for the job shouldn’t be a full-time job. Indeed’s simple and powerful tools let you source, screen, and hire faster.",
                            url: "https://www.indeed.com/hire"
                          },
                          {
                            description:
                              "Make sure to create your company profile on Glassdoor. Your next hire is researching your company on Glassdoor. Take control of the conversation and show ideal candidates why they want to work with you.",
                            url: "https://www.glassdoor.com/employers/sign-up/"
                          },
                          {
                            description:
                              "64% of job seekers get hired through a referral. Use LinkedIn Jobs to boost your chances of getting hired through people you know.",
                            url: "https://www.linkedin.com/jobs/"
                          },
                          {
                            description:
                              "Have your job postings found by millions of job seekers who search on Google every day.",
                            url: "https://jobs.google.com/"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          // Acceptance: Product
          {
            refKey: "acceptance_product_lc",
            name: "Product",
            description: "?",
            fa_tasks: [
              {
                refKey: "file_patents",
                name: "File for trademarks and patents",
                action_items: [
                  {
                    refKey: "action_file_patents",
                    name: "File for trademarks and patents",
                    sub_action_items: [
                      {
                        refKey: "file_patents",
                        name: "File for trademarks and patents",
                        description:
                          "The best thing to do is consult an attorney early about the need for patents, especially.  Get the advice early. Then you may be able to defer filing for a while, depending on the nature of your business.",
                        user_answer: determineValue("file_patents"),
                        resources: [
                          {
                            description:
                              "KissPatent offers a straightforward idea protection process tailored to growing businesses, fully supported by our team of experts.",
                            url: "https://kisspatent.com/"
                          },
                          {
                            description:
                              "Seventh makes intellectual property secure and simple. Seventh helps venture-backed companies find the business value in their intellectual property.",
                            url: "https://seventh.ai/"
                          },
                          {
                            description:
                              "The US Patent and Trademark office offers free searches, so you can protect your IP and brand over time.",
                            url: "https://www.uspto.gov/"
                          },
                          {
                            description:
                              "Going global? Google allows you to search and read the full text of patents from around the world.",
                            url: "https://patents.google.com/"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          // Acceptance: Market
          {
            refKey: "acceptance_market_lc",
            name: "Market",
            description: "?",
            fa_tasks: [
              {
                refKey: "register_domain",
                name: "Register a domain name",
                action_items: [
                  {
                    refKey: "action_register_domain",
                    name: "Register a domain name",
                    sub_action_items: [
                      {
                        refKey: "register_domain",
                        name: "Register a domain name",
                        description:
                          "Get a matching domain to your business name.  An AOL email address or a website with free hosting and a name like mysite.wordpress.com makes it seem like either (a) you are not running a real business or (b) you don’t plan to be around long.",
                        user_answer: determineValue("register_domain"),
                        resources: [
                          {
                            description:
                              "Google can help you find your place online. Choose from more than 300 domain endings. Get 24/7 Google support from real humans.",
                            url: "https://domains.google/"
                          },
                          {
                            description:
                              "GoDaddy is the world's largest services platform for entrepreneurs around the globe. They're on a mission to empower our worldwide community of 20+ million customers — and entrepreneurs everywhere — by giving them all the help and tools they need to grow online.",
                            url: "https://www.godaddy.com/"
                          },
                          {
                            description:
                              "Ionos offers a free domain with a web hosting package, including domain and email account, privacy protection and Wildcard SSL (https:).",
                            url:
                              "https://www.ionos.com/domains/free-domain-name"
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "social_media",
                name: "Register social media profiles",
                action_items: [
                  {
                    refKey: "action_social_media",
                    name: "Register social media profiles",
                    sub_action_items: [
                      {
                        refKey: "social_media",
                        name: "Register social media profiles",
                        description:
                          "Getting set up on the major social media channels (Facebook, LinkedIn, and Twitter, to start) will make marketing on them later easier. Also, it’s important to reserve your brand as a profile name. Try Knowem.com to reserve the names.",
                        user_answer: determineValue("social_media"),
                        resources: [
                          {
                            description:
                              "Easily manage all your social media and get results with Hootsuite. With the free starter account, you can manage three different social media accounts, including your choice among LinkedIn, Facebook, Twitter, Instagram and others.",
                            url: "https://www.hootsuite.com/"
                          },
                          {
                            description:
                              "Create a LinkedIn business page. Drive brand awareness & quality engagement with professional audiences by creating a LinkedIn Company Page. Explore how you can join conversations, grow & engage your audience. Learn more.",
                            url:
                              "https://business.linkedin.com/marketing-solutions/linkedin-pages"
                          },
                          {
                            description:
                              "Use the Twitter microblogging and social networking platform to reach your audience.",
                            url: "https://twitter.com/"
                          },
                          {
                            description:
                              "Facebook for Business gives you the latest news, advertising tips, best practices and case studies for using Facebook to meet your business goals.",
                            url: "https://www.facebook.com/business"
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                refKey: "order_cards",
                name: "Order business cards",
                action_items: [
                  {
                    refKey: "action_business_cards",
                    name: "Order business cards",
                    sub_action_items: [
                      {
                        refKey: "business_cards",
                        name: "Order business cards",
                        description:
                          "As a startup founder, you’ll be doing a lot of networking, so order plenty of business cards. They are inexpensive enough that you can reorder them later if things change. Without cards you lack credibility.",
                        user_answer: determineValue("business_cards"),
                        resources: [
                          {
                            description:
                              "VistaPrint allows you to create create business cards as unique as you.",
                            url: "https://www.vistaprint.com/business-cards"
                          },
                          {
                            description:
                              "Moo allows you to order a sample pack so you can touch and feel the premium range of papers and finishes for yourself. It’s free!",
                            url: "https://www.moo.com/"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  };
};
