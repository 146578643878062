import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.scss";
import { Dashboard } from "../Dashboard/Dashboard";
import { Playbook } from "../Playbook/Playbook";
import { MTM } from "../MTM/MTM";
import { Team } from "../Team/Team";
import gritQuiz from "../Team/gritQuiz";
import eqQuiz from "../Team/eqQuiz";
import influencerQuiz from "../Team/influencerQuiz";
import teamQuiz from "../Team/teamQuiz";
import visionaryQuiz from "../Team/visionaryQuiz";
import confidenceQuiz from "../Team/confidenceQuiz";

import { Glossary } from "../Glossary/Glossary";
import { Profile } from "../Profile/Profile";
import Onboarding from "../Onboarding/Onboarding";

// import Users from "../../../secure/containers/Users/Users";
import Login from "../../../public/Login";
import Register from "../../../public/Register";
import LogoutPage from "../../../public/Logout";
import PasswordReset from "../../../public/PasswordReset";
import PasswordSet from "../../../public/PasswordSet";
import ErrorBoundary from "../Error/ErrorBoundary";
import Header from "../Header/Header";
import { appRoutes } from "../../../redux/actions/routes";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import PrivateRoute from "../../components/PrivateRoute/PrivateRoute";
import { getAuthStatus } from "../../../redux/selectors/authSelectors";
import { StoreState } from "../../../redux";

// eslint-disable-next-line
function App(props: any) {
  const { appRoutes } = props;

  const routesArray = [
    {
      id: 1,
      path: "/",
      component: (
        <PrivateRoute
          key={1}
          exact
          path={"/"}
          component={Dashboard}
          {...props}
        />
      )
    },
    // {
    //   id: 2,
    //   path: "/users",
    //   component: Users
    // },
    {
      id: 3,
      path: "/login",
      component: <Route key={3} path={"/login"} component={Login} {...props} />
    },
    {
      id: 4,
      path: "/register",
      component: (
        <Route
          key={4}
          exact
          path={"/register"}
          component={Register}
          {...props}
        />
      )
    },
    {
      id: 5,
      path: "/playbook",
      component: (
        <PrivateRoute
          key={5}
          exact
          path={"/playbook"}
          component={Playbook}
          {...props}
        />
      )
    },
    {
      id: 6,
      path: "/mtm",
      component: (
        <PrivateRoute key={6} exact path={"/mtm"} component={MTM} {...props} />
      )
    },
    {
      id: 7,
      path: "/team",
      component: (
        <PrivateRoute
          key={7}
          exact
          path={"/team"}
          component={Team}
          {...props}
        />
      )
    },
    {
      id: 8,
      path: "/glossary",
      component: (
        <PrivateRoute
          key={8}
          exact
          path={"/glossary"}
          component={Glossary}
          {...props}
        />
      )
    },
    {
      id: 9,
      path: "/profile",
      component: (
        <PrivateRoute
          key={9}
          exact
          path={"/profile"}
          component={Profile}
          {...props}
        />
      )
    },
    {
      id: 10,
      path: "/password-reset",
      component: (
        <Route
          key={10}
          exact
          path={"/password-reset"}
          component={PasswordReset}
          {...props}
        />
      )
    },
    {
      id: 11,
      path: "/logout",
      component: (
        <Route
          key={11}
          exact
          path={"/logout"}
          component={LogoutPage}
          {...props}
        />
      )
    },
    {
      id: 12,
      path: "/onboarding",
      component: (
        <PrivateRoute
          key={12}
          exact
          path={"/onboarding"}
          component={Onboarding}
          {...props}
        />
      )
    },
    {
      id: 13,
      path: "/grit-scale",
      component: (
        <PrivateRoute
          key={13}
          exact
          path={"/grit-scale"}
          component={gritQuiz}
          {...props}
        />
      )
    },
    {
      id: 14,
      path: "/confidence-scale",
      component: (
        <PrivateRoute
          key={14}
          exact
          path={"/confidence-scale"}
          component={confidenceQuiz}
          {...props}
        />
      )
    },
    {
      id: 15,
      path: "/eq-scale",
      component: (
        <PrivateRoute
          key={15}
          exact
          path={"/eq-scale"}
          component={eqQuiz}
          {...props}
        />
      )
    },
    {
      id: 16,
      path: "/influencer-scale",
      component: (
        <PrivateRoute
          key={16}
          exact
          path={"/influencer-scale"}
          component={influencerQuiz}
          {...props}
        />
      )
    },
    {
      id: 17,
      path: "/team-scale",
      component: (
        <PrivateRoute
          key={17}
          exact
          path={"/team-scale"}
          component={teamQuiz}
          {...props}
        />
      )
    },
    {
      id: 18,
      path: "/visionary-scale",
      component: (
        <PrivateRoute
          key={18}
          exact
          path={"/visionary-scale"}
          component={visionaryQuiz}
          {...props}
        />
      )
    },
    {
      id: 19,
      path: "/set-password",
      component: (
        <Route
          key={19}
          path={"/set-password"}
          component={PasswordSet}
          {...props}
        />
      )
    }
  ];

  useEffect(() => {
    appRoutes(routesArray);
  }, [routesArray, appRoutes]);

  return (
    <Router>
      <ErrorBoundary>
        <Header />
      </ErrorBoundary>
      <div className="App" data-testid="App">
        <Switch>
          {routesArray.map((data) => (data ? data.component : null))}
        </Switch>
      </div>
    </Router>
  );
}

App.propTypes = {
  appRoutes: PropTypes.func,
  match: PropTypes.object,
  isLoggedIn: PropTypes.bool
};

const mapStateToProps = (state: StoreState): { isLoggedIn: boolean } => {
  return { isLoggedIn: getAuthStatus(state) };
};

export default connect(mapStateToProps, { appRoutes })(App);
