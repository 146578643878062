import axios from "axios";
import { Dispatch } from "redux";
import { ActionTypes } from "../types";
import { StoreState } from "../../redux";
import { getOrgId } from "../selectors/authSelectors";

export interface Auth {
  loggedIn: boolean;
  token: string;
  loaded: boolean;
  org_name: string;
  org_id: string;
  org_stage: string;
  onboarding_survey_completed: boolean;
}

export interface LoginExpectation {
  org_name: string;
  token: string;
  org_id: string;
  org_stage: string;
  onboarding_survey_completed: boolean;
}

export interface LoginInput {
  email: string;
  password: string;
  scope: string;
}

export interface LoginAction {
  type: ActionTypes.loginUser;
  payload: LoginExpectation;
}

export const loginUser = (LoginInput: LoginInput) => {
  const url = `${process.env.REACT_APP_SERVER_API_URL}api`;
  const loginURL = `${url}/login`;
  // use Dispatch as type annotation
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.post<LoginExpectation>(loginURL, LoginInput);
      // Success 🎉
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("org_id", response.data.org_id);
      localStorage.setItem("org_name", response.data.org_name);
      localStorage.setItem(
        "onboarded",
        String(response.data.onboarding_survey_completed)
      );

      dispatch<LoginAction>({
        type: ActionTypes.loginUser,
        payload: response.data
      });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      console.log(error);
    }
  };
};

// logout action

export interface LogoutExpectation {
  detail: string;
}

export interface LogoutAction {
  type: ActionTypes.logoutUser;
  payload: LogoutExpectation;
}

export const logoutUser = () => {
  // use Dispatch as type annotation
  return async (dispatch: Dispatch) => {
    localStorage.removeItem("token");
    localStorage.removeItem("org_id");
    localStorage.removeItem("org_name");
    localStorage.removeItem("onboarded");
    dispatch<LogoutAction>({
      type: ActionTypes.logoutUser,
      payload: { detail: "logging out" }
    });
  };
};

// loadData

export interface LoadDataExpectation {
  detail: string;
}

export interface LoadDataAction {
  type: ActionTypes.loadData;
  payload: LoadDataExpectation;
}

export const loadData = () => {
  // use Dispatch as type annotation
  return async (dispatch: Dispatch) => {
    dispatch<LoadDataAction>({
      type: ActionTypes.loadData,
      payload: { detail: "Loading Data" }
    });
  };
};

// TODO: reset password action

// register action

export interface RegisterExpectation {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  role: any;
  organization: {
    id: string;
    created: string;
    updated: string;
    name: string;
    industry: string;
    security_phone: string;
    support_email: string;
    token_expiration: string;
    last_score_calculation: string;
    date_joined: string;
    is_active: boolean;
    organization_type: string;
    organization_stage: string;
    org_id: string;
    onboarding_survey_completed: boolean;
  };
}

export interface RegisterInput {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  company_name: string;
  company_industry: string;
  role: number;
  password_confirm: string;
  organization: { name: string; industry: string };
}

export interface RegisterAction {
  type: ActionTypes.registerUser;
  payload: RegisterExpectation;
}

export const registerUser = (registerInput: RegisterInput) => {
  const url = `${process.env.REACT_APP_SERVER_API_URL}api`;
  const registerURL = `${url}/register`;
  // use Dispatch as type annotation
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.post<RegisterExpectation>(
        registerURL,
        registerInput
      );
      // Success 🎉
      dispatch<RegisterAction>({
        type: ActionTypes.registerUser,
        payload: response.data
      });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      console.log(error);
    }
  };
};

// Onboarding

export interface OnboardingExpectation {
  data: {
    organization_stage: string;
    org_id: string;
    onboarding_survey_completed: boolean;
  };
}

export interface OnboardingInput {
  stage: string;
  engagement: string;
  cofounders: string;
  cofounders_div: string;
  skillsets: string;
}

export interface OnboardingAction {
  type: ActionTypes.onboardUser;
  payload: OnboardingExpectation;
}

export const onboardUser = (onboardingInput: OnboardingInput) => {
  // use Dispatch as type annotation
  return async (dispatch: Dispatch, getState: () => StoreState) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      };
      const orgId = getOrgId(getState());
      const url = `${process.env.REACT_APP_SERVER_API_URL}api`;
      const onboardURL = `${url}/onboarding/${orgId}`;
      axios.defaults.withCredentials = true;

      const onboardingPostObject = {
        mtm_development_stage: onboardingInput.stage,
        ob_engagement: onboardingInput.engagement,
        mtm_cofounders_total: onboardingInput.cofounders,
        ob_div_cofounders: onboardingInput.cofounders_div,
        ob_skillsets: onboardingInput.skillsets
      };

      const response = await axios.post<OnboardingExpectation>(
        onboardURL,
        onboardingPostObject,
        config
      );
      localStorage.setItem(
        "onboarded",
        String(response.data.data.onboarding_survey_completed)
      );

      // Success 🎉
      dispatch<OnboardingAction>({
        type: ActionTypes.onboardUser,
        payload: response.data
      });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        if (error.response.status == 401) {
          // If get error from server that token is not ok, send back to login
          localStorage.removeItem("token");
          dispatch<LogoutAction>({
            type: ActionTypes.logoutUser,
            payload: error.response.data
          });
        }
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      console.log(error);
    }
  };
};

// Password reset: email

export interface RequestPasswordInput {
  email: string;
}

export interface RequestPasswordExpectation {
  status: string;
}

export const requestPassword = (requestPasswordInput: RequestPasswordInput) => {
  const url = `${process.env.REACT_APP_SERVER_API_URL}api`;
  const requestPasswordURL = `${url}/reset-password`;
  // use Dispatch as type annotation
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.post<RequestPasswordExpectation>(
        requestPasswordURL,
        requestPasswordInput
      );
      // Success 🎉
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      console.log(error);
    }
  };
};

// Set password

export interface ResetPasswordInput {
  password: string;
  password_confirm: string;
  token: string;
  email: string;
}

export interface ResetPasswordExpectation {
  status: string;
}

export const resetPassword = (ResetPasswordInput: ResetPasswordInput) => {
  // use Dispatch as type annotation
  return async (dispatch: Dispatch, getState: () => StoreState) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${ResetPasswordInput.token}`
        }
      };
      const url = `${process.env.REACT_APP_SERVER_API_URL}api`;
      const resetPasswordURL = `${url}/set-password`;
      axios.defaults.withCredentials = true;

      const passwordPostObject = {
        password: ResetPasswordInput.password,
        password_confirm: ResetPasswordInput.password_confirm,
        email: ResetPasswordInput.email
      };

      const response = await axios.post<ResetPasswordExpectation>(
        resetPasswordURL,
        passwordPostObject,
        config
      );

      // Success 🎉
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        if (error.response.status == 401) {
          // If get error from server that token is not ok, send back to login
          localStorage.removeItem("token");
          dispatch<LogoutAction>({
            type: ActionTypes.logoutUser,
            payload: error.response.data
          });
        }
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      console.log(error);
    }
  };
};
