import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import Wrapper from "../../components/Wrapper/Wrapper";
import { pathURL } from "../../../redux/actions/routes";

// Using match with typescript is weird, used this logic to set up the type:
// https://stackoverflow.com/questions/48138111/what-typescript-type-should-i-use-to-reference-the-match-object-in-my-props
interface MatchParams {
  name: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {
  path: string;
  url: string;
}

interface AppProps {
  pathURL: (path: string, url: string) => void;
  match: MatchProps;
}

class _Profile extends React.Component<AppProps> {
  constructor(props: AppProps) {
    super(props);
  }

  componentDidMount() {
    this.props.pathURL(this.props.match.path, this.props.match.url);
  }

  render() {
    return (
      <div className="App">
        <Wrapper>
          <div>Profile & Settings</div>
        </Wrapper>
      </div>
    );
  }
}

export const Profile = connect(null, {
  pathURL
})(_Profile);
