import React, { Component } from "react";
import axios from "axios";
import "./Public.scss";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { pathURL } from "../redux/actions/routes";

class PasswordReset extends Component {
  constructor() {
    super();
    this.state = {
      error: false
    };
  }
  email = "";
  password = "";
  state = {
    redirect: false
  };

  submit = async (e) => {
    e.preventDefault();

    const response = await axios.post("api/reset-password", {
      email: this.email
    });

    this.setState({
      redirect: true
    });
  };
  componentDidMount() {
    const { match } = this.props;
    this.props.pathURL(match.path, match.url);
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/login"} />;
    }

    return (
      <div>
        <form className="form-signin" onSubmit={this.submit}>
          <h1 className="h3 mb-3 font-weight-normal">Please sign in</h1>
          <label htmlFor="inputEmail" className="sr-only">
            Email address
          </label>
          <input
            type="email"
            id="inputEmail"
            className="form-control"
            placeholder="Email address"
            required
            onChange={(e) => (this.email = e.target.value)}
          />
          <button className="btn btn-lg btn-primary btn-block" type="submit">
            Change Password
          </button>
        </form>
        <div>
          <Link to="/login" className="btn btn-primary">
            Login
          </Link>
          <Link to="/register" className="btn btn-primary">
            Register
          </Link>
        </div>
      </div>
    );
  }
}

PasswordReset.propTypes = {};

export default connect(null, { pathURL })(PasswordReset);
