import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Collapse from "react-bootstrap/Collapse";
import { SubActionItems } from "./SubActionItems";

export const ActionItems = ({
  handleValueUpdate: handleValueUpdate,
  ...action
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Card key={action.refKey}>
      <Card.Title
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        // eventKey={action.refKey}
      >
        <div key={action.refKey}>
          <h3>{action.name}</h3>
        </div>
      </Card.Title>
      <Collapse in={open}>
        <div id="example-collapse-text">
          {action.sub_action_items.map((sub) => (
            <SubActionItems
              handleValueUpdate={handleValueUpdate}
              key={sub.refKey}
              {...sub}
            />
          ))}
        </div>
      </Collapse>
    </Card>
  );
};
