import React from "react";
import Image from "react-bootstrap/Image";
import Navbar from "react-bootstrap/Navbar";

const BrandNav = () => (
  <Navbar
    id="de-brand-navbar"
    collapseOnSelect
    expand="md"
    bg="light"
    variant="light"
    sticky="top"
  >
    <Navbar.Brand href="#home">
      <Image
        src="https://dealengine-client-assets.s3-us-west-2.amazonaws.com/dealengine-logo-red.svg"
        fluid
        alt="DealEngine logo, a rocket taking off over an upwards trending bar chart"
      />
    </Navbar.Brand>
  </Navbar>
);

export default BrandNav;
