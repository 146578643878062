import { teamData, Action, ActionTypes } from "../actions";

const initialState: teamData = {
  quizzes: {
    grit: [],
    eq: [],
    visionary: [],
    influencer: [],
    confidence: [],
    team: [],
    safety: []
  },
  team: []
};

export function teamReducer(state = initialState, action: Action): teamData {
  switch (action.type) {
    case ActionTypes.loadQuiz:
      return {
        ...state,
        quizzes: {
          ...state.quizzes,
          [action.payload.index]: action.payload.data.data
        }
      };
    default:
      return state;
  }
}
