import React, { Component } from "react";
import "./Public.scss";
import { Redirect } from "react-router-dom";
import { registerUser } from "../redux/actions/auth";
import { connect } from "react-redux";
import { pathURL } from "../redux/actions/routes";
import { getAuthStatus } from "../redux/selectors/authSelectors";
import Form from "react-bootstrap/Form";

// Multi-step flow from this guide: https://css-tricks.com/the-magic-of-react-based-multi-step-forms/

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      validated: false,
      currentStep: 1,
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      company_name: "",
      company_industry: ""
    };
  }

  handleSubmit = async (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({
        validated: true
      });
      e.preventDefault();
    } else {
      const {
        first_name,
        last_name,
        email,
        password,
        company_name,
        company_industry
      } = this.state;
      this.props.registerUser({
        first_name: first_name,
        last_name: last_name,
        email: email,
        role: 1,
        password: password,
        password_confirm: password,
        organization: { name: company_name, industry: company_industry }
      });

      this.setState({
        redirect: true
      });
    }
  };
  componentDidMount() {
    const { match } = this.props;
    this.props.pathURL(match.path, match.url);
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  };

  _next = () => {
    let currentStep = this.state.currentStep;
    currentStep = currentStep >= 1 ? 2 : currentStep + 1;
    this.setState({
      currentStep: currentStep
    });
  };

  _prev = () => {
    let currentStep = this.state.currentStep;
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep
    });
  };

  /*
   * the functions for our button
   */
  previousButton() {
    let currentStep = this.state.currentStep;
    if (currentStep !== 1) {
      return (
        <button
          className="btn btn-secondary"
          type="button"
          onClick={this._prev}
        >
          Previous
        </button>
      );
    }
    return null;
  }

  nextButton() {
    let currentStep = this.state.currentStep;
    if (currentStep < 2) {
      return (
        <button
          className="btn btn-primary float-right"
          type="button"
          onClick={this._next}
        >
          Continue
        </button>
      );
    }
    return null;
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/login"} />;
    }

    return (
      <React.Fragment>
        <h1 className="h3 mb-3 font-weight-normal">Create an Account</h1>
        <h1 className="h3 mb-3 font-weight-normal">
          Welcome to Deal Engine!...
        </h1>
        <p>Step {this.state.currentStep} </p>

        <Form
          className="form-signin"
          onSubmit={this.handleSubmit}
          noValidate
          validated={this.state.validated}
        >
          {/*
          render the form steps and pass required props in
        */}
          <Step1
            currentStep={this.state.currentStep}
            handleChange={this.handleChange}
            email={this.state.email}
          />
          <Step2
            currentStep={this.state.currentStep}
            handleChange={this.handleChange}
            username={this.state.username}
          />
          {this.previousButton()}
          {this.nextButton()}
        </Form>
      </React.Fragment>
    );
  }
}

function Step1(props) {
  if (props.currentStep !== 1) {
    return null;
  }
  return (
    <div>
      <Form.Group className="form-group">
        <Form.Label htmlFor="first_name">First Name</Form.Label>
        <Form.Control
          className="form-control"
          id="first_name"
          name="first_name"
          type="text"
          placeholder="First Name"
          value={props.first_name}
          onChange={props.handleChange}
          required
        />
      </Form.Group>
      <Form.Group className="form-group">
        <Form.Label htmlFor="last_name">Last Name</Form.Label>
        <Form.Control
          className="form-control"
          id="last_name"
          name="last_name"
          type="text"
          placeholder="Last Name"
          value={props.last_name}
          onChange={props.handleChange}
          required
        />
      </Form.Group>
      <Form.Group className="form-group">
        <Form.Label htmlFor="email">Email</Form.Label>
        <Form.Control
          className="form-control"
          id="email"
          name="email"
          type="text"
          placeholder="Enter email"
          value={props.email}
          onChange={props.handleChange}
          isInvalid={
            props.email &&
            !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
              props.email
            )
          }
          required
        />
        <Form.Control.Feedback type="invalid">
          Please enter a valid email.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="form-group">
        <Form.Label htmlFor="password">Password</Form.Label>
        <Form.Control
          className="form-control"
          id="password"
          name="password"
          type="password"
          placeholder="Enter password"
          value={props.password}
          onChange={props.handleChange}
          isInvalid={
            props.password &&
            !/^(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/.test(props.password)
          }
          required
        />
        <Form.Control.Feedback type="invalid">
          Please choose a stronger password.
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
}

const industries = [
  { code: "ADT", name: "Adtech" },
  { code: "ADM", name: "Advanced Manufacturing" },
  { code: "AID", name: "AI & Big Data" },
  { code: "BFA", name: "Beauty & Fashion" },
  { code: "BIO", name: "Biopharma" },
  { code: "CRY", name: "Blockchain and Crypto" },
  { code: "CLT", name: "CleanTech" },
  { code: "CON", name: "Consumer Electronics" },
  { code: "CYB", name: "Cybersecurity" },
  { code: "DIH", name: "Digital Health" },
  { code: "DIM", name: "Digital Media" },
  { code: "EDT", name: "Edtech" },
  { code: "ENE", name: "Energy & Environmental" },
  { code: "ENT", name: "Enterprise" },
  { code: "FIN", name: "Fintech" },
  { code: "GAM", name: "Gaming" },
  { code: "GOV", name: "GovTech" },
  { code: "MED", name: "MedTech" },
  { code: "MOB", name: "Mobile" },
  { code: "PRT", name: "PropertyTech" },
  { code: "RET", name: "Retail & Ecommerce" },
  { code: "ROB", name: "Robotics" },
  { code: "SMC", name: "Smart City" },
  { code: "SOC", name: "Social Media" },
  { code: "SPA", name: "SpaceTech" },
  { code: "SPT", name: "SportsTech" },
  { code: "TEL", name: "Telecom/5G" },
  { code: "TRA", name: "Transportation" },
  { code: "TRV", name: "Travel" },
  { code: "OTH", name: "Other" }
];

function Step2(props) {
  if (props.currentStep !== 2) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="form-group">
        <Form.Group className="form-group">
          <Form.Label htmlFor="company_name">Company Name</Form.Label>
          <Form.Control
            className="form-control"
            id="company_name"
            name="company_name"
            type="text"
            placeholder="Company Name"
            value={props.company_name}
            onChange={props.handleChange}
            required
          />
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label htmlFor="company_industry">Choose an option:</Form.Label>
          <Form.Control
            as="select"
            id="company_industry"
            name="company_industry"
            defaultValue={props.company_industry}
            onChange={props.handleChange}
            required
          >
            <option value={""} key="options" hidden>
              {"Select One"}
            </option>
            {industries.map((data) => (
              <option value={data.name} key={data.code}>
                {data.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </div>
      <button className="btn btn-success btn-block">Get Started</button>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  success: getAuthStatus(state)
});

Register.propTypes = {};

export default connect(mapStateToProps, { pathURL, registerUser })(Register);
