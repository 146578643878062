import "./Dashboard.scss";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { StoreState } from "../../../redux";
import { pathURL } from "../../../redux/actions/routes";
import DealDonut from "../../components/Charts/DealDonut";
import { TestScoreDonut } from "../../components/Charts/TwoLayer";
import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import ProgressBar from "react-bootstrap/ProgressBar";
import QsCompleted from "../../components/Charts/QsCompleted";
import Row from "react-bootstrap/Row";
import Wrapper from "../../components/Wrapper/Wrapper";

// Using match with typescript is weird, used this logic to set up the type:
// https://stackoverflow.com/questions/48138111/what-typescript-type-should-i-use-to-reference-the-match-object-in-my-props
interface MatchParams {
  name: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {
  path: string;
  url: string;
}

interface AppProps {
  // Because typescript can't understand a redux thunk action
  // Need to make fetchTodos a generic function instead of
  // strict type

  pathURL: (path: string, url: string) => void;
  match: MatchProps;
}

interface AppState {
  fetching: boolean;
}

class _Dashboard extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    this.state = { fetching: false };
  }

  componentDidMount() {
    this.props.pathURL(this.props.match.path, this.props.match.url);
  }

  componentDidUpdate(prevProps: AppProps): void {
    // replace this with another data load if needed
    // if (!prevProps.todos.length && this.props.todos.length) {
    //   this.setState({ fetching: false });
    // }
  }

  render() {
    return (
      <div className="App dash-wrap">
        <Wrapper>
          <CardDeck>
            <Card>
              <Card.Body>
                <Card.Title>
                  <h2>dealscore</h2>
                </Card.Title>
                <Card.Text>
                  Answer more questions to improve your Dealscore.
                </Card.Text>
                <TestScoreDonut
                  data={[
                    { score_type: "product", value: 15 },
                    { score_type: "market", value: 25 },
                    { score_type: "money", value: 35 },
                    { score_type: "team", value: 12 },
                    { score_type: "deal", value: 33 }
                  ]}
                />
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <Card.Title>
                  <h2>playbook progress</h2>
                </Card.Title>
                <Card.Text>
                  Find your progress in different areas of the{" "}
                  <a href="/playbook">Playbook</a>.
                </Card.Text>
              </Card.Body>
              {/* TODO what vars look like: now={now} label={`${now}%`} srOnly, also this is technically a nav element aria-labelledby="playbook-navigation */}
              <Container aria-labelledby="playbook-navigation">
                {/* TODO anchor tags need to autopopulate to id of section/tab */}
                <a href="/playbook">
                  <Row>
                    <Col className="progress-cap">Team</Col>
                    <Col className="team-bar">
                      <ProgressBar now={50}></ProgressBar>
                    </Col>
                  </Row>
                </a>
                <a href="/playbook">
                  <Row>
                    <Col className="progress-cap">Product</Col>
                    <Col className="product-bar">
                      <ProgressBar now={30}></ProgressBar>
                    </Col>
                  </Row>
                </a>
                <a href="/playbook">
                  <Row>
                    <Col className="progress-cap">Market</Col>
                    <Col className="market-bar">
                      <ProgressBar now={40}></ProgressBar>
                    </Col>
                  </Row>
                </a>
                <a href="/playbook">
                  <Row>
                    <Col className="progress-cap">Money</Col>
                    <Col className="money-bar">
                      <ProgressBar now={70}></ProgressBar>
                    </Col>
                  </Row>
                </a>
              </Container>
            </Card>
          </CardDeck>
          <CardDeck>
            <Card>
              <Card.Body>
                <Card.Title>
                  <h2>next steps</h2>
                </Card.Title>
                <Card.Text>
                  Take these next steps to improve your business. Dont forget to
                  come back and check off what you completed.
                </Card.Text>
              </Card.Body>
              <Nav className="flex-column">
                {/* TODO: put a character limit on the first and second lines so that the alignment doesn't change */}
                <Nav.Link href="/playbook">
                  <div>
                    Evaluate Business Plan
                    <br />
                    <span>Update Team Profile</span>
                  </div>
                </Nav.Link>
                <Nav.Link href="/team">
                  <div>
                    Evaluate Business Plan
                    <br />
                    <span>Update Team Profile</span>
                  </div>
                </Nav.Link>
                <Nav.Link href="/playbook">
                  <div>
                    Evaluate Business Plan
                    <br />
                    <span>Update Team Profile</span>
                  </div>
                </Nav.Link>
                <Nav.Link href="/mtm">
                  <div>
                    Evaluate Business Plan
                    <br />
                    <span>Update Team Profile</span>
                  </div>
                </Nav.Link>
              </Nav>
            </Card>
            <Card>
              <Card.Body>
                <Card.Title>
                  <h2>metrics that matter</h2>
                </Card.Title>
                <Card.Text>
                  Answers to these important questions will give investors a
                  better picture of your company.
                </Card.Text>
              </Card.Body>
              <QsCompleted />
            </Card>
          </CardDeck>
          {/* <Row>
            <div>
              <button onClick={this.onButtonClick}>fetch</button>
              {this.state.fetching ? "LOADING" : null}
              {this.renderList()}
            </div>
          </Row> */}
        </Wrapper>
      </div>
    );
  }
}

// const mapStateToProps = (state: StoreState): {} => {
//   return {};
// };

export const Dashboard = connect(null, {
  pathURL
})(_Dashboard);
