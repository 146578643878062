import "./Nav.scss";
import React from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

// Need to use LinkContainer as wrapper to react-bootstrap
const DENav = () => {
  return (
    <Navbar
      id="de-navbar"
      collapseOnSelect
      expand="sm"
      bg="light"
      variant="light"
      sticky="top"
    >
      <div className="container">
        <Nav className="mr-auto" aria-labelledby="primary-navigation">
          <DropdownButton
            title="Current Page"
            variant="outline-info"
            id="nav-dropdown-item-button"
          >
            <NavDropdown.Item as={Link} to={"/"}>
              Dashboard
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={Link} to={"/playbook"}>
              Playbook
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={Link} to={"/mtm"}>
              Metrics
            </NavDropdown.Item>
            {/* mtm#testdiv */}
            <NavDropdown.Divider />
            <NavDropdown.Item as={Link} to={"/team"}>
              Team
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={Link} to={"/glossary"}>
              Glossary
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={Link} to={"/profile"}>
              Profile &amp; Settings
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={Link} to={"/logout"}>
              Sign out
            </NavDropdown.Item>
          </DropdownButton>
        </Nav>
        <Navbar.Text className="d-none d-sm-block">
          Your Company
          {/* import Image */}
          <Image></Image>
        </Navbar.Text>
      </div>
    </Navbar>
  );
};

export default DENav;
