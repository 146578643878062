import axios from "axios";
import { Dispatch } from "redux";
import { ActionTypes } from "../types";
import { LogoutAction, LoadDataAction } from "../actions/auth";
import { StoreState } from "../../redux";
import { getOrgId } from "../selectors/authSelectors";
import { generatePlaybook } from "../fixtures/PlaybookData";

export interface EnumPlaybookItem {
  refKey: string;
  name: string;
  should_show: boolean; // this will determine if we should grey out
  is_current: boolean; // this will determine if this is where the nav tree opens
  lc_areas: any;
}

export interface EnumPlaybookSubItem {
  refKey: string;
  user_answer: string;
}

// for comment: make it so when a value is updated it makes comment null, and the comment section defaults to "check back tomorrow..." if null

export interface PlaybookData {
  data: Array<EnumPlaybookItem>;
}

export interface PlaybookExpectation {
  data: [];
}

export interface PlaybookAction {
  type: ActionTypes.getPlaybook;
  payload: PlaybookData;
}

export const getPlaybook = () => {
  // use Dispatch as type annotation
  return async (dispatch: Dispatch, getState: () => StoreState) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      };

      const orgId = getOrgId(getState());
      const url = `${process.env.REACT_APP_SERVER_API_URL}api`;
      const playbookURL = `${url}/playbook/${orgId}`;

      axios.defaults.withCredentials = true;

      const response = await axios.get<PlaybookData>(playbookURL, config);

      const theData = generatePlaybook(response.data.data[0]);

      dispatch<PlaybookAction>({
        type: ActionTypes.getPlaybook,
        payload: theData
      });
      dispatch<LoadDataAction>({
        type: ActionTypes.loadData,
        payload: { detail: "Loading Data" }
      });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        if (error.response.status == 401) {
          // If get error from server that token is not ok, send back to login
          localStorage.removeItem("token");
          dispatch<LogoutAction>({
            type: ActionTypes.logoutUser,
            payload: error.response.data
          });
        }
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      console.log(error);
    }
  };
};

export interface LoadPlaybookDataAction {
  type: ActionTypes.loadPlaybookUpdateData;
  payload: PlaybookData;
}

export const loadPlaybookUpdateData = (data: EnumPlaybookItem[]) => {
  // use Dispatch as type annotation
  return async (dispatch: Dispatch) => {
    dispatch<LoadPlaybookDataAction>({
      type: ActionTypes.loadPlaybookUpdateData,
      payload: { data: data }
    });
  };
};

export interface PostPlaybookDataAction {
  type: ActionTypes.postPlaybook;
  payload: PlaybookData;
}

// postMtm

export interface playbookPostExpectation {
  data: string;
}

export const postPlaybook = (data: EnumPlaybookSubItem) => {
  // use Dispatch as type annotation
  return async (dispatch: Dispatch, getState: () => StoreState) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      };

      const orgId = getOrgId(getState());
      const url = `${process.env.REACT_APP_SERVER_API_URL}api`;
      const playbookURL = `${url}/playbook/${orgId}/`;
      axios.defaults.withCredentials = true;

      const playbookPostObject = {
        question_key: data.refKey,
        user_answer: data.user_answer
      };

      const response = await axios.post<playbookPostExpectation>(
        playbookURL,
        playbookPostObject,
        config
      );
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        if (error.response.status == 401) {
          // If get error from server that token is not ok, send back to login
          localStorage.removeItem("token");
          dispatch<LogoutAction>({
            type: ActionTypes.logoutUser,
            payload: error.response.data
          });
        }
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      console.log(error);
    }
  };
};
