import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { StoreState } from "../../../redux";
import "./Playbook.scss";
import Wrapper from "../../components/Wrapper/Wrapper";
import { pathURL } from "../../../redux/actions/routes";
import { getPlaybookData } from "../../../redux/selectors/playbookSelectors";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";

import { ActionItems } from "./ActionItems";

import {
  loadPlaybookUpdateData,
  PlaybookData,
  postPlaybook,
  EnumPlaybookItem
} from "../../../redux/actions/playbook";

// Using match with typescript is weird, used this logic to set up the type:
// https://stackoverflow.com/questions/48138111/what-typescript-type-should-i-use-to-reference-the-match-object-in-my-props
interface MatchParams {
  name: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {
  path: string;
  url: string;
}

interface AppProps {
  pathURL: (path: string, url: string) => void;
  match: MatchProps;
  playbook: EnumPlaybookItem[];
  loadPlaybookUpdateData: (data: PlaybookData) => void;
  postPlaybook: (data: EnumPlaybookItem) => void;
}

class _Playbook extends React.Component<AppProps> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      user_answer: false,
      answers: [{ user_answer: false }]
    };
  }

  componentDidMount() {
    this.props.pathURL(this.props.match.path, this.props.match.url);
  }

  // if (activity !== third.refKey) return third;
  // return { ...third, user_answer: evt.target.checked };

  handleValueUpdate = (activity: any) => (evt: any) => {
    const firstValue = this.props.playbook.map((first) => {
      const secondValue = first.lc_areas.map((second) => {
        const thirdValue = second.fa_tasks.map((third) => {
          const fourthValue = third.action_items.map((fourth) => {
            const fifthValue = fourth.sub_action_items.map((fifth) => {
              if (activity !== fifth.refKey) return fifth;
              this.props.postPlaybook({
                ...fifth,
                user_answer: evt.target.checked
              });
              return { ...fifth, user_answer: evt.target.checked };
            });

            return { ...fourth, sub_action_items: fifthValue };
          });

          return { ...third, action_items: fourthValue };
        });
        return { ...second, fa_tasks: thirdValue };
      });
      return { ...first, lc_areas: secondValue };
    });

    this.props.loadPlaybookUpdateData(firstValue);
    this.setState({ answers: firstValue });
  };

  render() {
    console.log(this.props.playbook);
    return (
      <div className="App">
        <Wrapper>
          {/* This tab system goes horizontal , but cannot render mapped data */}
          {/* <Tabs
            defaultActiveKey="home"
            transition={false}
            id="noanim-tab-example"
          >
            <Tab eventKey="Evidence" title="Evidence">
              Tab 1
            </Tab>
            <Tab eventKey="Acceptance" title="Acceptance">
              Tab 2
            </Tab>
            <Tab eventKey="Formula" title="Formula" disabled>
              Tab 3
            </Tab>
            <Tab eventKey="Match" title="Match" disabled>
              Tab 4
            </Tab>
            <Tab eventKey="Bonding" title="Bonding" disabled>
              Tab 5
            </Tab>
            <Tab eventKey="Velocity" title="Velocity" disabled>
              Tab 6
            </Tab>
          </Tabs> */}

          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            {this.props.playbook.map((lifecycle: any) => (
              <Nav
                variant="pills"
                className="flex-column"
                key={lifecycle.refKey}
              >
                <Nav.Item>
                  <Nav.Link eventKey={lifecycle.name}>
                    {lifecycle.name}
                  </Nav.Link>
                </Nav.Item>
                <Tab.Content>
                  <Tab.Pane eventKey={lifecycle.name}>
                    {lifecycle.lc_areas.map((category: any) => (
                      <div key={category.refKey}>
                        <h2>{category.name}</h2>
                        <div>{category.description}</div>
                        {category.fa_tasks.map((activity: any) => (
                          <Card key={activity.refKey}>
                            <Accordion>
                              <Accordion.Toggle
                                as={Card.Header}
                                eventKey={activity.refKey}
                              >
                                <div key={activity.refKey}>{activity.name}</div>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey={activity.refKey}>
                                <Card.Body>
                                  {activity.action_items.map((action: any) => (
                                    <ActionItems
                                      handleValueUpdate={this.handleValueUpdate}
                                      key={action.refKey}
                                      {...action}
                                    />
                                  ))}
                                </Card.Body>
                              </Accordion.Collapse>
                            </Accordion>
                          </Card>
                        ))}
                      </div>
                    ))}
                  </Tab.Pane>
                </Tab.Content>
              </Nav>
            ))}
          </Tab.Container>
        </Wrapper>
      </div>
    );
  }
}

const mapStateToProps = (state: StoreState): { playbook: any } => {
  return { playbook: getPlaybookData(state) };
};

export const Playbook = connect(mapStateToProps, {
  pathURL,
  loadPlaybookUpdateData,
  postPlaybook
})(_Playbook);
