import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Collapse from "react-bootstrap/Collapse";
import Form from "react-bootstrap/Form";

export const SubActionItems = ({
  handleValueUpdate: handleValueUpdate,
  ...sub
}) => {
  const [open, setOpen] = useState(false);
  return (
    <Card key={sub.refKey}>
      <Card.Header
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text2"
        aria-expanded={open}
      >
        <div key={sub.refKey}>
          <div key={sub.refKey}>
            <Form.Check
              type="checkbox"
              label={sub.name}
              checked={sub.user_answer}
              onChange={handleValueUpdate(sub.refKey)}
            />
          </div>
        </div>
      </Card.Header>
      <Collapse in={open}>
        <Card.Body>
          <div id="example-collapse-text2">
            <div key={sub.refKey}>
              <div>{sub.description}</div>
              <br></br>
              <div>RESOURCES:</div>
              <ul>
                {sub.resources.map((resource) => (
                  <li key={resource.url}>
                    <a href={resource.url} target="_blank" rel="noreferrer">
                      {resource.description}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Card.Body>
      </Collapse>
    </Card>
  );
};
