import { createSelector } from "reselect";

export const getTeam = (state) => state.team || {};

export const getTeamMembers = createSelector([getTeam], (team) => {
  return team.team;
});

export const getTeamQuizzes = createSelector([getTeam], (team) => {
  return team.quizzes;
});

export const getQuizGrit = createSelector([getTeamQuizzes], (quiz) => {
  return quiz.grit;
});

export const getQuizEQ = createSelector([getTeamQuizzes], (quiz) => {
  return quiz.eq;
});

export const getQuizVisionary = createSelector([getTeamQuizzes], (quiz) => {
  return quiz.visionary;
});

export const getQuizInfluencer = createSelector([getTeamQuizzes], (quiz) => {
  return quiz.influencer;
});

export const getQuizConfidence = createSelector([getTeamQuizzes], (quiz) => {
  return quiz.confidence;
});

export const getQuizTeam = createSelector([getTeamQuizzes], (quiz) => {
  return quiz.team;
});

// Add the rest of the quizzes
