import axios from "axios";
import { Dispatch } from "redux";
import { ActionTypes } from "../types";
import { StoreState } from "../../redux";
import { LogoutAction, LoadDataAction } from "../actions/auth";
import { getOrgId } from "../selectors/authSelectors";

export interface EnumMtmItem {
  id: number;
  from_quiz: number;
  type: string;
  text: string;
  key: string;
  user_answer: number;
  choices: [];
  last_updated: string;
  description: string;
  help_url: string;
  max_validation: number;
  min_validation: number;
  rank: number;
}

// for comment: make it so when a value is updated it makes comment null, and the comment section defaults to "check back tomorrow..." if null

export interface mtmData {
  data: Array<EnumMtmItem>;
}

export interface mtmExpectation {
  data: [];
}

export interface mtmGetAction {
  type: ActionTypes.getMtm;
  payload: mtmData;
}

export const getMtm = () => {
  // use Dispatch as type annotation
  return async (dispatch: Dispatch, getState: () => StoreState) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      };
      const orgId = getOrgId(getState());

      const url = `${process.env.REACT_APP_SERVER_API_URL}api`;
      const mtmURL = `${url}/mtm/${orgId}`;

      axios.defaults.withCredentials = true;

      const response = await axios.get<mtmData>(mtmURL, config);
      console.log("Getting MTM data: ", response.data);
      dispatch<mtmGetAction>({
        type: ActionTypes.getMtm,
        payload: response.data
      });

      dispatch<LoadDataAction>({
        type: ActionTypes.loadData,
        payload: { detail: "Loading Data" }
      });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        if (error.response.status == 401) {
          // If get error from server that token is not ok, send back to login
          localStorage.removeItem("token");
          dispatch<LogoutAction>({
            type: ActionTypes.logoutUser,
            payload: error.response.data
          });
        }
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      console.log(error);
    }
  };
};

export interface LoadMtmDataAction {
  type: ActionTypes.loadMtmUpdateData;
  payload: mtmData;
}

export const loadMtmUpdateData = (data: EnumMtmItem[]) => {
  // use Dispatch as type annotation
  return async (dispatch: Dispatch) => {
    dispatch<LoadMtmDataAction>({
      type: ActionTypes.loadMtmUpdateData,
      payload: { data: data }
    });
  };
};

// postMtm

export interface mtmPostExpectation {
  data: string;
}

export interface PostMtmDataAction {
  type: ActionTypes.postMtm;
  payload: mtmData;
}

export interface mtmPostAction {
  type: ActionTypes.postMtm;
  payload: mtmPostExpectation;
}

export const postMtm = (data: EnumMtmItem) => {
  // use Dispatch as type annotation
  return async (dispatch: Dispatch, getState: () => StoreState) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      };

      const orgId = getOrgId(getState());
      const url = `${process.env.REACT_APP_SERVER_API_URL}api`;
      const mtmURL = `${url}/mtm/${orgId}`;
      axios.defaults.withCredentials = true;

      const mtmPostObject = {
        id: data.id,
        from_quiz: data.from_quiz,
        type: data.type,
        text: data.text,
        key: data.key,
        user_answer: data.user_answer
      };

      //   const response = await axios.post<mtmData>(mtmURL, config);
      const response = await axios.post<mtmPostExpectation>(
        mtmURL,
        mtmPostObject,
        config
      );
      // TODO: save new comment in redux
      dispatch<mtmPostAction>({
        type: ActionTypes.postMtm,
        payload: response.data
      });
      dispatch<LoadDataAction>({
        type: ActionTypes.loadData,
        payload: { detail: "Loading Data" }
      });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        if (error.response.status == 401) {
          // If get error from server that token is not ok, send back to login
          localStorage.removeItem("token");
          dispatch<LogoutAction>({
            type: ActionTypes.logoutUser,
            payload: error.response.data
          });
        }
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      console.log(error);
    }
  };
};
