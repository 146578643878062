import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { pathURL } from "../../../redux/actions/routes";
import { setError } from "../../../redux/actions/errors";
import {
  getAuthStatus,
  getLoaded,
  getOnboarding
} from "../../../redux/selectors/authSelectors";
import { getGlossary } from "../../../redux/actions/glossary";
import { getMtm } from "../../../redux/actions/mtm";
import { getQuiz } from "../../../redux/actions/team";
import { getPlaybook } from "../../../redux/actions/playbook";

import { getGlossaryData } from "../../../redux/selectors/glossarySelectors";
import { getMtmData } from "../../../redux/selectors/mtmSelectors";

// This Header component will become a smart component that will trigger
// the app's initial data fetches and Redux setup for the user/org
const Header = (props) => {
  const {
    routesArray,
    path,
    url,
    pathURL,
    setError,
    errors,
    isLoggedIn,
    getGlossary,
    glossaryData,
    isLoaded,
    getMtm,
    getQuiz,
    getPlaybook,
    isOnboarded
  } = props;

  const history = useHistory();

  // This hook checks if the url is a supported route, if it's not supported it throws an error and triggers the error boundary component catch. Note: In the development environment, the browser may show the error in the view, this will not happen in the Production environment... as a dev just hit esc and the error will disappear and show the error component as expected
  useEffect(() => {
    // once the routesArray is loaded, check if the current path exists
    if (routesArray.length) {
      // If a user is not yet logged in, send them to the login page
      if (!path && !url && !isLoggedIn) {
        history.push({
          pathname: "/login"
        });
      }
      // If user is logged in, check if it's a valid route
      if (!path && !url && isLoggedIn) {
        pathURL("/", "/");
        const error = new Error(
          `Page with pathname ${location.pathname} not found with status code 404.`
        );
        setError({
          message: `Page with pathname ${location.pathname} not found.`,
          statusCode: 404
        });
        throw error;
      }
    }
  }, [path, url, routesArray, pathURL]);

  useEffect(() => {
    if (errors.message || errors.statusCode) {
      pathURL("/", "/");
      const error = new Error(
        `${errors.message} With status code ${errors.statusCode} `
      );
      setError({
        message: `Page with pathname ${location.pathname} not found.`,
        statusCode: 404
      });
      throw error;
    }
    // eslint-disable-next-line
  }, [errors]);

  // make initial data calls here
  // only make these calls if the user is logged in and data hasn't loaded
  // TODO: make another check to wait if it's in the process of loading and add loading spinner to app
  if (isLoggedIn && isOnboarded && !isLoaded) {
    // need to delay a bit for the backend to store token upon login
    if (!getGlossaryData[0]) {
      setTimeout(() => {
        // Check if the data is empty and then call...prevents multiple calls
        // TODO, this is calling twice. Set up loading functionality
        if (!getGlossaryData[0]) {
          // getGlossary();
          getMtm();
          getPlaybook();
          getQuiz("grit", "PsyQuiz:Grit");
          getQuiz("eq", "PsyQuiz:EQ");
          getQuiz("visionary", "PsyQuiz:Visionary");
          getQuiz("influencer", "PsyQuiz:Influencer");
          getQuiz("confidence", "PsyQuiz:Confidence");
          getQuiz("team", "PsyQuiz:Team");
        }
      }, 1000);
    }
  }

  return <div></div>;
};

Header.propTypes = {
  routesArray: PropTypes.array,
  path: PropTypes.string,
  url: PropTypes.string,
  pathURL: PropTypes.func,
  errors: PropTypes.any
};

const mapStateToProps = (state) => ({
  routesArray: state.routes.routesArray,
  path: state.routes.path,
  url: state.routes.url,
  errors: state.errors,
  isLoggedIn: getAuthStatus(state),
  glossaryData: getGlossaryData(state),
  isLoaded: getLoaded(state),
  isOnboarded: getOnboarding(state)
});

export default connect(mapStateToProps, {
  pathURL,
  setError,
  getGlossary,
  getMtm,
  getQuiz,
  getPlaybook
})(Header);
