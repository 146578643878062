import { Auth, Action, ActionTypes } from "../actions";

const initialState: Auth = {
  loggedIn: false,
  token: "",
  loaded: false,
  org_name: "",
  org_id: "",
  org_stage: "Evidence",
  onboarding_survey_completed: false
};

export function authReducer(state = initialState, action: Action): Auth {
  switch (action.type) {
    case ActionTypes.logoutUser:
      console.log("logging out user");
      return {
        ...state,
        loggedIn: false,
        token: "",
        loaded: false
      };
    case ActionTypes.loginUser:
      return {
        ...state,
        loggedIn: action.payload.org_name ? true : false,
        token: action.payload.token,
        org_name: action.payload.org_name,
        org_id: action.payload.org_id,
        org_stage: action.payload.org_stage,
        onboarding_survey_completed: action.payload.onboarding_survey_completed
      };
    case ActionTypes.registerUser:
      return {
        ...state,
        org_name: action.payload.organization.name,
        org_id: action.payload.organization.org_id,
        org_stage: action.payload.organization.organization_stage,
        onboarding_survey_completed:
          action.payload.organization.onboarding_survey_completed
      };
    case ActionTypes.onboardUser:
      return {
        ...state,
        org_stage: action.payload.data.organization_stage,
        onboarding_survey_completed:
          action.payload.data.onboarding_survey_completed
      };
    case ActionTypes.loadData:
      console.log("load data!!!");
      return {
        ...state,
        loaded: true
      };
    default:
      return state;
  }
}
