import "./MTM.scss";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { StoreState } from "../../../redux";
import Wrapper from "../../components/Wrapper/Wrapper";
import { pathURL } from "../../../redux/actions/routes";
import { getMtmData } from "../../../redux/selectors/mtmSelectors";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";

import { SimpleSlider } from "../../components/SimpleSlider/SimpleSlider";
import {
  loadMtmUpdateData,
  mtmData,
  postMtm,
  EnumMtmItem
} from "../../../redux/actions/mtm";

// Using match with typescript is weird, used this logic to set up the type:
// https://stackoverflow.com/questions/48138111/what-typescript-type-should-i-use-to-reference-the-match-object-in-my-props
interface MatchParams {
  name: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {
  path: string;
  url: string;
}

interface AppProps {
  pathURL: (path: string, url: string) => void;
  match: MatchProps;
  mtm: EnumMtmItem[];
  loadMtmUpdateData: (data: mtmData) => void;
  postMtm: (data: EnumMtmItem) => void;
}

class _MTM extends React.Component<AppProps> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      user_answer: "",
      answers: [{ user_answer: "" }]
    };
  }

  componentDidMount() {
    this.props.pathURL(this.props.match.path, this.props.match.url);
  }

  postAnswer = (data: any): void => {
    this.props.postMtm(data);
  };

  handleValueUpdate = (idx: number, type: string) => (evt: any) => {
    const newValue = this.props.mtm.map((data, sidx) => {
      if (idx !== sidx + 1) return data;
      let theValue = evt.target.value;
      if (type === "int_pct") {
        theValue = parseInt(theValue) / 100;
      }
      return { ...data, user_answer: theValue };
    });
    this.props.loadMtmUpdateData(newValue);
    this.setState({ answers: newValue });
  };

  handleSliderChange = (data: any, e: any) => {
    const newValue = this.props.mtm.map((mtm, sidx) => {
      if (data.id !== sidx + 1) return mtm;
      return { ...mtm, user_answer: e.target.value };
    });
    this.props.loadMtmUpdateData(newValue);
    this.setState({ answers: newValue });
  };

  // getDefaultOption = (data: any) => {
  //   const theOption = data.user_answer;
  //   const findOption = data.choices.filter(function (value: {
  //     id: number;
  //     text: string;
  //     rank: number;
  //   }) {
  //     return value.text === theOption;
  //   })[0];
  //   return findOption && findOption.id;
  // };

  cleanInt = (val: any) => {
    if (val) {
      return parseInt(val);
    }
    return 0;
  };

  convertPercent = (val: any) => {
    if (val) {
      return val * 100;
    }
    return 0;
  };

  percentValidation = (val: any) => {
    if (val) {
      const percentVal = parseInt(val);
      if (percentVal < 0) return 0;
      if (percentVal > 100) return 100;
      return percentVal;
    }
    return 0;
  };

  render() {
    return (
      <div className="App metrics-wrap">
        <Wrapper>
          <h1>Metrics That Matter</h1>

          {this.props.mtm.map((data: EnumMtmItem) => (
            <Card key={data.key + "maindiv"}>
              <Card.Body>
                <div className="metrics-sub-item">Question: {data.text}</div>
                {data.type == "dd_option" && (
                  <div>
                    <label htmlFor={data.key}>Choose an option:</label>
                    <select
                      id={data.key}
                      name={data.key}
                      // value={data.user_answer}
                      // defaultValue={data.user_answer}
                      defaultValue={data.user_answer}
                      onChange={this.handleValueUpdate(data.id, data.type)}
                    >
                      <option value={""} key={data.key + "null"} hidden>
                        {"Select One"}
                      </option>
                      {data.choices.map((data: any) => (
                        <option value={data.text} key={data.key + data.text}>
                          {data.text}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                {data.type == "int" && (
                  <InputGroup className="mb-3">
                    <FormControl
                      type="number"
                      name={data.key}
                      key={data.key}
                      defaultValue={data.user_answer}
                      onChange={this.handleValueUpdate(data.id, data.type)}
                    />
                  </InputGroup>
                )}
                {data.type == "int_pct" && (
                  <InputGroup className="mb-3">
                    <FormControl
                      type="number"
                      name={data.key}
                      key={data.key}
                      onInput={(e: any) => {
                        e.target.value = this.percentValidation(e.target.value);
                      }}
                      defaultValue={this.convertPercent(data.user_answer)}
                      onChange={this.handleValueUpdate(data.id, data.type)}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                )}
                {data.type == "int_dol" && (
                  // https://react-bootstrap.netlify.app/components/input-group/
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text>$</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      aria-label="Amount (to the nearest dollar)"
                      type="number"
                      name={data.key}
                      key={data.key}
                      defaultValue={data.user_answer}
                      onChange={this.handleValueUpdate(data.id, data.type)}
                    />
                  </InputGroup>
                )}
                {data.type == "range_1_5" && (
                  <Form>
                    <Form.Group controlId={data.key}>
                      <Form.Label>Range</Form.Label>
                      <SimpleSlider
                        min={0}
                        max={5}
                        {...data}
                        defaultValue={this.cleanInt(data.user_answer)}
                        handleChange={this.handleSliderChange}
                      />
                    </Form.Group>
                  </Form>
                )}
                {data.type == "range_1_10" && (
                  <Form>
                    <Form.Group controlId={data.key}>
                      <Form.Label>Range</Form.Label>
                      <SimpleSlider
                        min={0}
                        max={10}
                        {...data}
                        defaultValue={this.cleanInt(data.user_answer)}
                        handleChange={this.handleSliderChange}
                      />
                    </Form.Group>
                  </Form>
                )}
                {data.type == "str" && (
                  <div>
                    Answer:{" "}
                    <input
                      type="text"
                      name={data.key}
                      key={data.key}
                      defaultValue={data.user_answer}
                      onChange={this.handleValueUpdate(data.id, data.type)}
                    />
                  </div>
                )}
                <div className="metrics-sub-item">
                  Description: {data.description}
                </div>
                {data.help_url && (
                  <div className="metrics-sub-item">
                    <a href={data.help_url} target="_blank" rel="noreferrer">
                      Click here for more info
                    </a>
                  </div>
                )}
                <div className="metrics-sub-item">
                  Last updated: {data.last_updated}
                </div>
                <Button
                  variant="outline-primary"
                  onClick={() => this.postAnswer(data)}
                >
                  Submit
                </Button>
              </Card.Body>
            </Card>
          ))}
        </Wrapper>
      </div>
    );
  }
}

const mapStateToProps = (state: StoreState): { mtm: EnumMtmItem[] } => {
  return { mtm: getMtmData(state) };
};

export const MTM = connect(mapStateToProps, {
  pathURL,
  loadMtmUpdateData,
  postMtm
})(_MTM);
