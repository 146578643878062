import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import Wrapper from "../../components/Wrapper/Wrapper";
import { StoreState } from "../../../redux";
import { pathURL } from "../../../redux/actions/routes";
import { getGlossaryData } from "../../../redux/selectors/glossarySelectors";
import { EnumGlossaryItem } from "../../../redux/actions";
import Table from "react-bootstrap/Table";

// Using match with typescript is weird, used this logic to set up the type:
// https://stackoverflow.com/questions/48138111/what-typescript-type-should-i-use-to-reference-the-match-object-in-my-props
interface MatchParams {
  name: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {
  path: string;
  url: string;
}

interface AppProps {
  pathURL: (path: string, url: string) => void;
  match: MatchProps;
  glossary: EnumGlossaryItem[];
}

class _Glossary extends React.Component<AppProps> {
  constructor(props: AppProps) {
    super(props);
  }

  componentDidMount() {
    this.props.pathURL(this.props.match.path, this.props.match.url);
  }

  render() {
    return (
      <div className="App">
        <Wrapper>
          <h1>Glossary</h1>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Term</th>
                <th>Acronymn</th>
                <th>Definition</th>
              </tr>
            </thead>
            <tbody>
              {this.props.glossary.map((data: EnumGlossaryItem) => (
                <tr key={"_glossary" + data.id}>
                  <td>{data.term}</td>
                  <td>{data.acronymn}</td>
                  <td>{data.definition}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Wrapper>
      </div>
    );
  }
}

const mapStateToProps = (
  state: StoreState
): { glossary: EnumGlossaryItem[] } => {
  return { glossary: getGlossaryData(state) };
};

export const Glossary = connect(mapStateToProps, {
  pathURL
})(_Glossary);
