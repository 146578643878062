import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";
import DENav from "../Nav/Nav";
import "../../../index.scss";

class Wrapper extends Component {
  render() {
    return (
      <>
        <DENav />
        <Container>
          <main role="main">{this.props.children}</main>
        </Container>
      </>
    );
  }
}

export default Wrapper;
